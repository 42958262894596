import React, { Component } from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import  OpenMap  from '../Layout/Map';

export default class BusinessView extends Component {

  masquerade_link = () => {
    let url = '/admin/masquerade/' + this.props.viewer._id + "/" + this.props.user._id;
    this.props.history.push(url);
  }

  printAgreementBtn = () => {
    let isActive = "disabled";
    let daClick = '';
    if (typeof this.props.user.agreement_file_id_fk != "undefined") {
      isActive = "";
      daClick = this.download_agreement
    }
    return (
      <div className="linkHolder" onClick={daClick}>
        <FontAwesomeIcon icon={["fal", "file-signature"]} className={isActive} />
        <div className="icon-text">Agreement</div>
      </div>
    )
  }

  download_agreement = () => {
    document.location = this.props.user.agreement_file_id_fk.path;
  }

  render() {
    const user = this.props.user;
    if(user === null)
      return null

    return (
      <div className="side-page">

          <div className="page_header">
            <div className="row">
              <div className="col-md-9">
                <FontAwesomeIcon icon={["fal", "building"]} className="business_icon_or_logo"/>
                <div>
                  <h1 className="bigger">{user.business_name}</h1>
                </div>
              </div>
              <div className="col-md-3 apply_container">

                {this.props.viewer.role === "Admin" || this.props.viewer.role === "Teacher" ? (
                  <>

                  <div className="linkHolder" onClick={this.props.swapApprovalState}>
                    {user.approved === true ? (<FontAwesomeIcon icon={["far", "toggle-on"]} />) : (<FontAwesomeIcon icon={["far", "toggle-off"]} />)}
                    <div className="icon-text">
                    {user.approved === true ? ("Activated") : ("De-Activated")}
                    </div>
                  </div>

                  <div className="linkHolder" onClick={this.masquerade_link}>
                    <FontAwesomeIcon icon={["fas", "user-secret"]} />
                    <div className="icon-text">Masquerade</div>
                  </div>

                  <div className="linkHolder" onClick={this.props.resetPassword}>
                    <FontAwesomeIcon icon={["fas", "key"]} />
                    <div className="icon-text">Reset PW</div>
                  </div>

                  </>
                ) : (null)}

                {this.printAgreementBtn()}

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 opportunity_view_side">

              {this.props.viewer.role === "Admin" || this.props.viewer.role === "Teacher" ? (
                <>
                <br /><FontAwesomeIcon icon={["fal", "key-skeleton"]} className="little-icons" /><b>Account Login: {user.email}</b><br />
                </>
              ) : (null)}

              {user.__v === 0 ? (null) : (
                <>
                <br /><FontAwesomeIcon icon={["fal", "user"]} className="little-icons" /> {user.contact_name}
                <br /><FontAwesomeIcon icon={["fal", "envelope"]} className="little-icons" /><a href={"mailto:"+user.contact_email}>{user.contact_email}</a>
                <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+user.contact_phone}>{user.contact_phone}</a>
                <br /><FontAwesomeIcon icon={["fal", "map-marker-alt"]} className="little-icons" /><a href={`https://www.google.com/maps/search/?api=1&query=${user.location.lat},${user.location.lon}`}>{user.location.street}, {user.location.city} {user.location.state} {user.location.zip}</a>
                <br />
                <br />
                </>
              )}

              {typeof(user.location) !== 'undefined' ? (
                <OpenMap location={user.location} />
              ) : (null)}

            </div>
            <div className="col-8 opportunity_view">

              <div className="top_section row">
                <div className="col-md-12">
                  <FontAwesomeIcon icon={["fal", "calendar-alt"]} className="big-icons" /> Partner Since {moment().format("YYYY")}
                </div>
              </div>

              {user.__v === 0 ? (
                <div>
                  <h2>User has not yet completed their profile.</h2>
                </div>
              ) : (
                <>
                <div dangerouslySetInnerHTML={{__html: user.business_bio}} />
                <h1>Featured Projects</h1>
                <div dangerouslySetInnerHTML={{__html: user.featured_projects}} />
                <h1>Career Opportunities</h1>
                <div dangerouslySetInnerHTML={{__html: user.career_opportunities}} />
                <h1>Work-Based Learning Opportunities</h1>
                <div dangerouslySetInnerHTML={{__html: user.internship_opportunities}} />
                </>
              )}



            </div>
          </div>

      </div>
    )
  }
}
