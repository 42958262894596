import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProjectTable extends Component {

  state = {
    user_role : this.props.user_role || 'student',
  }

    render () {
      if (this.props.projects === null || this.props.projects === undefined)
        return null

        return (
          this.props.projects.length ? (
            <>

            <div>
              <div className="row">
                <div className="col-12 table-responsive-sm d-none d-sm-block">
                  <table className="table table-striped table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" width="60%">Title</th>
                        <th scope="col" width="20%">Partner</th>
                        <th scope="col" width="5%">Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.projects.map(project => (
                        <tr key={project._id}>
                          {this.state.user_role === "Admin" || this.state.user_role === "Teacher" ? (
                            <td><Link to={'/admin/project/' + project._id} title="View Application">{project.opportunity_id_fk.opportunity_title}</Link></td>
                          ) : (
                            <td><Link to={'/project/' + project._id + '/hours'} title="View Application">{project.opportunity_id_fk.opportunity_title}</Link></td>
                          )}
                          <td>{project.business_id_fk.business_name}</td>
                          <td>{project.reportData.total_hours}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-12 table-responsive d-block d-sm-none">
                  {this.props.projects.map(project => (
                    <div key={project._id} className="mobile_application_opp_name">
                      <div>
                        {this.state.user_role === "Admin" || this.state.user_role === "Teacher" ? (
                          <Link to={'/admin/project/' + project._id} title="View Application" className="mobile_big_link" >{project.opportunity_id_fk.opportunity_title}</Link>
                        ) : (
                          <Link to={'/project/' + project._id + '/hours'} title="View Application" className="mobile_big_link" >{project.opportunity_id_fk.opportunity_title}</Link>
                        )}
                        <br />{project.business_id_fk.business_name}
                      </div>
                      <div>{project.reportData.total_hours} hours</div>

                      <div className="break"></div>

                    </div>
                  ))}
                </div>

              </div>
            </div>



            </>
          ) : (
            <div className="row">
              You do not have any projects. Please click the browse link above to search for an opportunity and then apply. Upon successful application and acceptance to an work-based learning opportunity you will have a project to complete.
            </div>
          )
        )
    }
};

export {ProjectTable}
