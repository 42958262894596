import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {reorder, getListStyle} from "../Shared/DragDrop"
import FocusAreaForm from './FocusAreaForm';
import swal from 'sweetalert';
import _ from 'underscore';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

class FocusAreaList extends Component {

  state = {
      ...this.props.user,
      focusAreas: [],
      reordered: false,
      viewAddNew: false
  }

  componentDidMount() {
   this.makeCall()
  }

  makeCall = () => {
    let link = "/api/getFocusAreas";
    if (this.props.user.role === "SuperAdmin")
      link = "/api/getDefaultFocusAreas";
    api({
      method: 'get',
      url: link,
    }).then(results => {
      this.setState({focusAreas: results.data})
    });
  }

  toggleNew = (requery) => {
    console.log("clicked")
    this.setState({viewAddNew: !this.state.viewAddNew});
    if (requery)
      this.makeCall();
  }
  
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, this.rerunSearch);
  };
  
  deleteConfirm = (user) => {
    let deleteFA = this.deleteFA;
    let message = "You are going to delete the focus area '" + user.name + "'";
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteFA(user._id);
      } 
    });
  }
  
  deleteFA = (id) => {
    let link = '/api/deleteFocusArea';
    let message = 'Focus Area successfully deleted.';
    let makeCall = this.makeCall;
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            makeCall();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }
  
  loadDefaultFA = () => {
    let makeCall = this.makeCall;
    axios({
      method: 'get',
      url: '/api/loadDefaultFocusAreas'
    }).then(function(response){
      makeCall();
      NotificationManager.success("You have saved the default Focus Areas", 'Success');
    })
  }

  onDragEnd = (result) => {
    console.log("this is the result: ", result)
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const focusAreas = reorder(
    this.state.focusAreas,
    result.source.index,
    result.destination.index
  );
  console.log("this is the items:", focusAreas)
  if (!_.isEqual(focusAreas, this.state.focusAreas)){
    console.log("reordered");
    this.setState({
      focusAreas, reordered: true
    });
  }
  
}

saveReorder = () => {
  let json = this.state.focusAreas;
  let link = '/api/reorderFocusAreas';
  let message = 'Reorder Successfull.';
  _.each(json, (j,k) => {
    j.ordinal = ++k
  });
  console.log("new json:", json);
  api({
      method: 'post',
      url: link,
      data: {
        json
      }
    }).then((response) => {
      console.log("this is the response:", response)
      this.setState({reordered: false})
        if (response.data === "success"){
          NotificationManager.success(message, 'Success');
        }
        else {
          NotificationManager.warning("Something went wrong", 'Uh Oh');
        }
      })
      
}


   render() {

     return (
       <div className="side-page">
         <div className="basic_form">

          <div className="page_header">
             <h1>{this.props.user.role === "SuperAdmin" ? "Default" : null }  Focus Areas
               {this.state.reordered ?
                  <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder} >
                   <FontAwesomeIcon icon={["fas", "save"]}  />Save Reorder
                   </Button> :
                  <span className="push-right withExplanation" onClick={this.toggleNew} >
                   {!this.state.viewAddNew ? 
                     <FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
                     <FontAwesomeIcon icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> }
                 </span>
                }
             </h1>
             {this.props.user.role === "SuperAdmin" ? 
              <div className="formExplanation">These Questions will be the defaults presented to new clients</div>
              :
             <div className="formExplanation">Focus areas are the areas of interest for your interns.  For example: (Business, or Computer Science)</div> }
             </div>
             
             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                 {!this.state.viewAddNew ? 
                   this.state.focusAreas.length > 0 ?
                     <table className="table table-striped table-hover business-list-table">
                       <thead className="thead-light">
                         <tr>
                           <th width="30px"></th>
                            <th scope="col" width="99%">Focus Areas</th>
                            <th scope="col" width="30px">Delete</th>
                         </tr>
                       </thead>
                       <DragDropContext onDragEnd={this.onDragEnd}>
                       <Droppable droppableId="components">
                         {(provided, snapshot) => (
                           <tbody className="basic_form" {...provided.droppableProps}
                             ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}>
                             {this.state.focusAreas.map((fa, index) => (
                               <Draggable key={fa._id} draggableId={fa._id} index={index}>
                               {(provided, snapshot) => (
                                 <FARow innerRef={provided.innerRef} obj={fa} provided={provided}
                                 deleteConfirm={this.deleteConfirm}
                                 dragHandle={<span {...provided.dragHandleProps}><FontAwesomeIcon icon={["fas", "bars"]} className="draghandle"  /></span>} />
                                 )}
                                 </Draggable>
                                 ))}
                                 {provided.placeholder}
                                 </tbody>
                                 )}  
                       </Droppable>
                       </DragDropContext>
                     </table>
                     : 
                     this.props.user.role === "SuperAdmin" ? <span>Please add default focus areas with the plus icon above</span> : <div className="two-buttons"><span className="first-button"><Button variant="contained" color="primary" onClick={this.toggleNew} >Add Your First Focus Area</Button></span> <Button variant="contained" color="primary" onClick={this.loadDefaultFA} >Start with Default Focus Areas</Button></div> 
                   : <FocusAreaForm toggleBack={this.toggleNew} /> }
                 </div>
               </div>
             </div>


         </div>
       </div>
     )
   }
}

export default connect(mapStateToProps)(FocusAreaList);

class FARow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
     <tr {...provided.draggableProps}
     ref={innerRef} key={this.props.obj._id}>
       <td>{this.props.dragHandle}</td>
       <td>{this.props.obj.name}</td>
       <td><span className="table_icons" onClick={()=>this.props.deleteConfirm(this.props.obj)}>
       <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
     </span></td>
     </tr>
    )
  }
}



