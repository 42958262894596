import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import QuestionForm from './QuestionForm';
import swal from "sweetalert"
import _ from "underscore"
import {reorder, getListStyle} from "../Shared/DragDrop"

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};


class EvaluationQuestions extends Component {

  state = {
    ...this.props.user,
    viewAddNew: false,
    reordered: false,
    questions: []
  }

  componentDidMount() {
    this.makeCalls();
  }

  getEvaluationQuestions = () => {
    let link = "/api/getMyEvaluationQuestions";
    if (this.props.user.role === "SuperAdmin")
      link = "/api/getDefaultEvaluationQuestions";
    return api({
      method: 'get',
      url: link
    });
  }


  makeCalls = () => {
    var that = this;
    axios.all([this.getEvaluationQuestions()])
    .then(axios.spread(function (questions) {
      that.setState({questions: questions.data});
    }));
  }
  
  toggleNew = (requery = false) => {
    this.setState({viewAddNew: !this.state.viewAddNew})
    if (requery)
      this.makeCalls();
  }
  
  onDragEnd = (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const questions = reorder(
    this.state.questions,
    result.source.index,
    result.destination.index
  );
  if (!_.isEqual(questions, this.state.questions)){
    this.setState({
      questions, reordered: true
    });
  }
  
}

  deleteQuestion = (id) => {
    let link = '/api/deleteEvaluationQuestion';
    let message = 'Question successfully deleted.';
    let rerunSearch = this.makeCalls;
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            rerunSearch();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }

deleteQuestionConfirm = (question) => {
  let deleteQuestion = this.deleteQuestion;
  let message = "You are going to delete this question";
  //swal("Are you Sure?", message, "warning");
  swal({
    title: "Are you sure?",
    text: message,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      deleteQuestion(question._id);
    } 
  });
}

saveReorder = () => {
  let json = this.state.questions;
  let link = '/api/reorderEvaluationQuestions';
  let message = 'Reorder Successfull.';
  _.each(json, (j,k) => {
    j.ordinal = ++k
  });
  console.log("new json:", json);
  api({
      method: 'post',
      url: link,
      data: {
        json
      }
    }).then((response) => {
      console.log("this is the response:", response)
      this.setState({reordered: false})
        if (response.data === "success"){
          NotificationManager.success(message, 'Success');
        }
        else {
          NotificationManager.warning("Something went wrong", 'Uh Oh');
        }
      })
      
}

loadDefaultQuestions = () => {
  let makeCalls = this.makeCalls;
  axios({
    method: 'get',
    url: '/api/loadDefaultEvaluationQuestions'
  }).then(function(response){
    makeCalls();
    NotificationManager.success("You have saved the default questions", 'Success');
  })
}
  
  render() {

    return (
      <div className="side-page">
      <div className="page_header">
       <h1>{this.props.user.role === "SuperAdmin" ? "Default" : null } Evaluation Questions
         
         
         {this.state.reordered ?
           <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder} >
           <FontAwesomeIcon icon={["fas", "save"]}  />Save Reorder
           </Button> :
           <span className="push-right withExplanation" onClick={this.toggleNew} >
            {!this.state.viewAddNew ? 
              <FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
              <FontAwesomeIcon icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> }
          </span>
         }
       </h1>
       {this.props.user.role === "SuperAdmin" ? 
        <div className="formExplanation">These Questions will be the default Evaluation Questions presented to new clients</div>
        :
        <div className="formExplanation">Evaluation questions are presented to businesses to give them an opportunity to evaluate the students as they complete their work-based learning opportunity.  For the default list of evaluation questions, click here</div> }
       </div>
           {!this.state.viewAddNew ? 
             this.state.questions.length === 0 ? 
              this.props.user.role === "SuperAdmin" ? <span>Please add default questions with the plus icon above</span> 
              : <div className="two-buttons"><span className="first-button"><Button variant="contained" color="primary" onClick={this.toggleNew} >Add Your First Evaluation Question</Button></span> <Button variant="contained" color="primary" onClick={this.loadDefaultQuestions} >Start with Default Questions</Button></div>  :
             <table className="table table-striped table-hover business-list-table">
              <thead className="thead-light">
                <tr>
                   <th scope="col" width="30px"></th>
                   <th scope="col" width="99%">Question</th>
                   <th scope="col" width="30px">Delete</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="components">
                  {(provided, snapshot) => (
                    <tbody className="basic_form" {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}>
                      {this.state.questions.map((question, index) => (
                        <Draggable key={question._id} draggableId={question._id} index={index}>
                        {(provided, snapshot) => (
                          <QuestionRow innerRef={provided.innerRef} question={question} provided={provided}
                          deleteQuestionConfirm={this.deleteQuestionConfirm}
                          dragHandle={<span {...provided.dragHandleProps}><FontAwesomeIcon icon={["fas", "bars"]} className="draghandle"  /></span>} />
                          )}
                          </Draggable>
                          ))}
                          {provided.placeholder}
                          </tbody>
                          )}  
                </Droppable>
                </DragDropContext>
             </table>
            : 
             <div className="basic_form"><QuestionForm user={this.props.user} toggleBack={this.toggleNew} api="/api/createEvaluationQuestion" /></div>
       
       }
    </div>
    )
  }
}

export default connect(mapStateToProps)(EvaluationQuestions);

class QuestionRow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
     <tr {...provided.draggableProps}
     ref={innerRef}>
       <td>{this.props.dragHandle}</td>
       <td>{this.props.question.question_text}</td>
       <td><span className="table_icons" onClick={()=>this.props.deleteQuestionConfirm(this.props.question)}>
       <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
     </span></td>
     </tr>
    )
  }
}

/*<DragDropContext onDragEnd={this.onDragEnd}>
<Droppable droppableId=“components”>
{(provided, snapshot) => (
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
      style={getListStyle(snapshot.isDraggingOver)}
    >
    {this.state.items.map((item, index) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <NMPGCard child={item.content} dragHandle={<span {...provided.dragHandleProps}><FontAwesomeIcon icon={[“fas”, “arrows-alt”]} className=“draghandle arrows-alt”  /></span>} />
            </div>
          )}
        </Draggable>
        ))}
    {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext>*/
  
  
 