import React, { Component } from 'react';
import {api} from "../../_helpers"
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import moment from 'moment';
import ld from 'lodash'

export default class InternPanel_Hours extends Component {

  state = {
    hours : this.props.hours,
    approved_hours : this.props.approved_hours
  }

  business_setApproval = (hours_id, new_state) => {
    let json = {
      project_id : this.props.project_id,
      hours_id : hours_id,
      business_approved : new_state
    }

    let status = 'approved';
    if (new_state === -1)
      status = 'disapproved';

    api({
      method: 'post',
      url: '/api/project_update_hoursApproval',
      data: {
        json
      }
    }).then((response) => {
      NotificationManager.success('Hours successfully ' + status + ' .', 'Hours Update Successfull');
      let hours_item = ld.find(this.state.hours, {_id : hours_id});
      hours_item.business_approved = json.business_approved;

      let new_total = this.state.approved_hours;
      if (json.business_approved === 1)
        new_total += hours_item.total_hours;

      this.setState(this.state)
      this.setState({ approved_hours : new_total})
    });
  }

  hoursLine = () => {
    let first_unapproved = ld.find(this.state.hours, ['business_approved', 0]);

    if (typeof(first_unapproved) !== 'undefined') {
      return (
        <div className="row hours_line_item">
          <div className="col-5">{moment(first_unapproved.start_date).format("MM/DD")} - {moment(first_unapproved.end_date).format("MM/DD")}</div>
          <div className="col-2">{first_unapproved.total_hours}</div>
          <div className="col-5 hours_actions">
            <div onClick={() => {this.business_setApproval(first_unapproved._id, 1)}}><i className="fal fa-check-circle" title="Approve"></i></div>
            <div onClick={() => {this.business_setApproval(first_unapproved._id, -1)}}><i className="fal fa-times-circle" title="Do Not Approve"></i></div>
          </div>
        </div>
      )
    }

    else
      return (
        <div className="no_hours">There are no hours to approve</div>
      )
  }

  render() {

    return (
      <div className="internPanel_hoursPanel">
        <div className="total_hours_print">Total Completed Hours: <Link to={'/interns/hours_log/' + this.props.project_id}>{this.state.approved_hours}</Link></div>

        <div>
          {this.hoursLine()}
        </div>
      </div>
    )
  }
}
