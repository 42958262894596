import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {PhoneMask} from '../../_masks';
import {setUser } from '../../ducks';
import ProfilePic from '../Layout/ProfilePic';
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RTEHelper, ContentEditable, MultiSelect, SingleSelect} from '../../_formhelpers';
import {ClickToViewMap} from '../Layout/EditMap';
import {getStreetCords, getZipCords} from '../../_map'
import {api} from "../../_helpers"

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    is_masquerade : state.auth.isMasquerade,
    admin_id : state.auth.masquerade_user
  };
};

const actions = {
  setUser
}

class ConnectedBusinessProfileWrapped extends Component {

  state = {
    db: {
      states: [],
      focusAreas: [],
      programs: [],
      schools: [],
      shrink: {
        contact_phone: (this.props.user.contact_phone) ? true : false,
        admin_phone: (this.props.user.admin_phone) ? true : false
      }
    },
    ...this.props.user
  }

  componentDidMount() {
    this.makeCalls();
  }

  getStates = () => {
    return axios({
      method: 'get',
      url: 'api/getStates',
    });
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: 'api/getPrograms',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: 'api/getFocusAreas',
    });
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: 'api/getSchools',
    });
  }

  getFile = () => {
    return axios({
      method: 'get',
      url: 'api/getFileById',
      params: {id: this.props.user.agreement_file_id_fk}
    })
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getSchools(), this.getFocusAreas(), this.getStates(), this.getPrograms(), this.getFile()])
    .then(axios.spread(function (schools, focusAreas, states, programs, file) {
      that.setState(state => ((state.db.schools = schools.data, state)));
      that.setState(state => ((state.db.focusAreas = focusAreas.data, state)));
      that.setState(state => ((state.db.states = states.data, state)));
      that.setState(state => ((state.db.programs = programs.data, state)));
      that.setState(state => ((state.db.file = file.data, state)));
    }));
  }

    updateLocation = () => {
        this.setState(state => ((state.location.userOverwroteLocation = true, state)));
      }

  makeOSMCalls = () => {
    if (this.state.location.userOverwroteLocation)
      return fetch("/auth/userdata").then(response => false);

    var that = this;
    let cord = {};
    return axios.all([getStreetCords(this.state.location), getZipCords(this.state.location)])
    .then(axios.spread(async (streetcords, zipcords) => {
      console.log("stuff:", streetcords, zipcords);
      if (streetcords) { // location query successful

        cord.lat = streetcords.lat;
        cord.lon = streetcords.lon;
      }
      else if (zipcords) { //fall back to zip
        NotificationManager.error('Your address is not found, trying zip code.', 'Error');
        cord.lat = zipcords.lat;
        cord.lon = zipcords.lon;
      }
      else { //fall back to they are dummies
        NotificationManager.error('Your address is not found, location queries will not work.', 'Error');
        cord.lat = 0;
        cord.lon = 0;
      }
      that.setState(state => ((state.location.lat = cord.lat, state)));
      that.setState(state => ((state.location.lon = cord.lon, state)));
      return true
    })).catch(error => {
      NotificationManager.error('Error Reaching all APIS.', 'Something Really Bad Went Wrong');
      return false
    });
  }

  handleChange = event => {
    console.log("this is:", event.target.value)
    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskChange = event => {
    //let that = this;
    let name = event.target.name;
    if (event.target.value.length > 0)
        this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));

    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskFocus = event => {
    let name = event.target.name;
    this.setState(state => ((state.db.shrink[name] = true, state)));
  };

  handleMaskBlur = event => {
    let name = event.target.name;
    if (event.target.value.length > 0)
      this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));
  };

  handleNestedChange(event, nestedItem ) {
    let val = event.target.value;
    let name = event.target.name;
    if (typeof this.state[nestedItem] !== "undefined") //if the nested object doesn't exist in state
      this.setState(state => ((state[nestedItem][name] = val, state)));
    else {
      this.setState({[nestedItem]: {}});
      this.setState(state => ((state[nestedItem][name] = val, state)));
    }
  }

  handleRTEChange = (field, value) => {
   this.setState({ [field] : value.toString("html") });
 }

 handleBusinessChange = event => {
   console.log("event:", event);
   this.setState({business_name: event.target.value})
 }

 clickInput = event => {
   document.getElementById("fileUpload").click();
 }


  handleCheck = event => {
    this.setState({ [event.target.name] : event.target.checked });
  };

  formErrors = (errors) => {
    NotificationManager.error('Check Your Form for errors', 'Form Error');
  }

  updateProfile = (e) => {
    let profile = this.state;
    let setUser = this.props.setUser;
    let history = this.props.history;
    console.log("this is history:", history);
    let payload = {
      is_masquerade : this.props.is_masquerade,
      admin_id : this.props.admin_id
    }

    let errors =  document.getElementsByClassName('error', 'react-select-container');
    if (errors.length > 0){
      NotificationManager.error('Error Saving.', 'Check your form for errors');
      return false;
    }

    axios.all([this.makeOSMCalls()])
      .then(data => {
        if (data[0]){
      api({
        method: 'post',
        url: '/api/user',
        data: {
          profile
        }
      }).then(function (response) {
        if (response.data.errors === undefined  &&  response.data.name === undefined){
          //console.log("this is the response: ", response.data);
          payload.user = response.data;
          setUser(payload);

          NotificationManager.success('Profile Updated.', 'Save Successfull');
          history.push("/default");
        }
        else {
          NotificationManager.error('Error Saving.', 'Something Went Wrong');
        }
      });
    }
    });

  }

  handleFileUpload = e => {
    e.preventDefault();
    let setUser = this.props.setUser;
    let jsonData = {
      user_id : this.props.user._id,
    }
    let that=this;
    let link = "/api/user_saveFile";
    const formData = new FormData()
    formData.append('fileupload', e.target.files[0]);
    formData.append('description', 'business_agreement_file');
    api.post("/api/upload_file", formData, {
    }).then(res => {
      console.log(res)
      that.setState({resume_file_id_fk: res.data._id});
      that.setState(state => ((state.db.file = res.data, state)));
      jsonData.file_id = res.data._id;
      jsonData.key = "agreement_file_id_fk";
      console.log("this is jsonData:", jsonData);
      NotificationManager.success('Agreement Successfully Uploaded', 'Successfull');
      api.post(link, jsonData, {
      }).then(res => {
        axios.get("/api/current-user").then(function(response){
          let payload = {
            user : response.data.user,
            is_masquerade : response.data.is_masquerade,
            admin_id : response.data.admin_id
          }
          setUser(payload);
        })
      });

    }).catch(error => {
        NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
    });
  };

  render() {

    return (
      <div className="side-page">
      <div className="basic_form">
        <ValidatorForm
          ref="form"
          onSubmit={() => this.updateProfile()}
          onError={errors => this.formErrors(errors)}
          instantValidate={true}
        >
          <div className="inline-block">
            <ProfilePic />
          </div>
          <ContentEditable className="inline-block business_title" name="business_name" value={(this.state.business_name !== null ? this.state.business_name : "Type your business name here")} onChange={this.handleChange} />

            <div className="row">
              <div className="col-md-6">
                <h4>Company Information</h4>


                <RTEHelper
                    label="Business Bio (~150-250 words) *"
                    name="business_bio"
                    onChange={this.handleRTEChange}
                    value={this.props.user.business_bio}
                />

                <RTEHelper
                    label="Featured Projects and Opportunities *"
                    name="featured_projects"
                    onChange={this.handleRTEChange}
                    value={this.state.featured_projects}
                />

                <RTEHelper
                    label="Career Opportunities: (Types of Careers/Job Titles found at Your Company) *"
                    name="career_opportunities"
                    onChange={this.handleRTEChange}
                    value={this.state.career_opportunities}
                />

                <RTEHelper
                    label="Work-Based Learning Opportunities: (Types of Work-Based Learning Opportunities you Will Offer) *"
                    name="internship_opportunities"
                    onChange={this.handleRTEChange}
                    value={this.state.internship_opportunities}
                />

                {/*
                <TextValidator
                  name="employee_count" label="Number of Employees" variant="outlined"
                  onChange={this.handleChange} value={this.state.employee_count}
                  className="inputs" validators={['required']} errorMessages={['this field is required']}
                />

              <SingleSelect label="Is This a Woman or Minority-owned Business" name="woman_owned" onChange={this.handleChange} value={this.state.woman_owned}
                    options={[{value: "No", label: "No"},{value: "Yes", label: "Yes"}]} />

                    */}

                <MultiSelect label="Preferred Work-Based Learning Focus Area(s)" name="focus_areas" onChange={this.handleChange}
                  value={this.state.focus_areas}
                  options={this.state.db.focusAreas.map(s =>
                    {
                      return {value: s.name, label: s.name};
                    })} />

                <MultiSelect label="Preferred Schools" name="schools" onChange={this.handleChange}
                value={this.state.schools}
                options={this.state.db.schools.map(s =>
                  {
                    return {value: s.name, label: s.name};
                  })} />
              </div>

              <div className="col-md-5 offset-md-1">
                <h4>Primary Location Information</h4>
                {this.state.location ?
                <ClickToViewMap id={this.state._id} location={this.state.location} collection="User" updateLocation={this.updateLocation} /> : null }
                  <TextValidator
                    name="street" label="Street" variant="outlined"
                    onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.street}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                  <TextValidator
                    name="city" label="City" variant="outlined"
                    onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.city}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                <SingleSelect required label="State" name="state" onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.state}
                      options={this.state.db.states.map(o =>
                        {
                          return {value: o.code, label: o.name, key: o._id};
                        })} />

                  <TextValidator
                    name="zip" label="Zip" variant="outlined"
                    onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.zip}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                <h4>Contact Information</h4>
                  <TextValidator
                    name="contact_name" label="Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.contact_name}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                  <TextValidator
                    name="contact_email" label="Email" variant="outlined"
                    onChange={this.handleChange} value={this.state.contact_email}
                    className="inputs" validators={['required', 'isEmail']} errorMessages={['this field is required', 'must be a valid email address']}
                  />

                  <TextValidator
                    name="contact_phone" label="Phone" variant="outlined"
                      onChange={this.handleMaskChange} value={this.state.contact_phone}
                      onFocus={this.handleMaskFocus}
                      onBlur={this.handleMaskBlur}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                      placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                      InputLabelProps={{ shrink: this.state.db.shrink.contact_phone }}
                  />

                  <TextValidator
                    name="admin_name" label="Admin Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.admin_name}
                    className="inputs"
                  />

                  <TextValidator
                    name="admin_email" label="Admin Email" variant="outlined"
                    onChange={this.handleChange} value={this.state.admin_email}
                    className="inputs"
                  />

                  <TextValidator
                    name="admin_phone" label="Admin Phone" variant="outlined"
                    onChange={this.handleMaskChange} value={this.state.admin_phone}
                    onFocus={this.handleMaskFocus}
                    onBlur={this.handleMaskBlur}
                    className="inputs"
                    placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                    InputLabelProps={{ shrink: this.state.db.shrink.admin_phone }}
                  />

                <h4><FontAwesomeIcon icon={["far", "download"]}/>Work-Based Learning Agreement</h4>

                {this.state.db.file !== undefined ?
                  this.state.db.file._id !== null ? (
                    <><a href={this.state.db.file.path}>{this.state.db.file.name}</a><br /></>
                  ):(null):(null)}

              <Button variant="contained" color="default" onClick={this.clickInput}>
                {this.state.db.file === "no files" ? ("Upload Agreement \u00a0"):("Replace Agreement \u00a0")}
                <FontAwesomeIcon icon={["far", "upload"]}/>
              </Button>

              <input
                name="fileUpload"
                id="fileUpload"
                label="Agreement"
                onChange={this.handleFileUpload}
                type="file"
                style={{display:"none"}}
              />

              {/*
              <p <p>I have read, understand and agree to all the terms presented in the Business Exploratory Work-Based Learning Agreement.
                By clicking I agree I am indicating with an electronic signature that I agree to all terms.</p>

              <FormControlLabel
                control={
                  <Checkbox name="agreement" checked={this.state.agreement} onChange={this.handleCheck} color="primary" />
                }
                label="I Agree with the above"
              />
              */}

              <div className="agreement_instructions mt-3">
                <ol type="1">
                  <li>Download the Work-Based Learning agreement from the Resources tab</li>
                  <li>Read and sign the agreement</li>
                  <li>Upload the agreement to the your profile</li>
                </ol>
                <p>*Your account will not be approved until a signed agreement has been uploaded</p>
              </div>

              </div>

            </div>

            <div className="mobile-center-button">
            <Button type="submit" variant="contained" color="primary" className="btn_action">
              Update Profile
            </Button>
            </div>

        </ValidatorForm>
      </div>
    </div>
    )
  }
}

const ConnectedBusinessProfile = withRouter(ConnectedBusinessProfileWrapped)
export default connect(mapStateToProps, actions)(ConnectedBusinessProfile);
