import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {setUser, updateSettings } from '../../ducks';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import {api} from "../../_helpers"
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

const actions = {
  setUser, updateSettings
}

class ConnectedAdminDashboard extends Component {

  state = {
    ...this.props.user,
    db: {
      schools: [],
      programs: [],
      focusAreas: [],
      reflectionQuestions: [],
      evaluationQuestions: []
    }
  }

  componentDidMount() {
    console.log("complete?:", this.props.settings.is_complete)
    if (!this.props.settings.is_complete)
      this.makeCalls();
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: '/api/getPrograms',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: '/api/getFocusAreas',
    });
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: '/api/getSchools',
    });
  }
  
  getReflectionQuestions = () => {
    return axios({
      method: 'get',
      url: '/api/getMyReflectionQuestions',
    });
  }
  
  getEvaluationQuestions = () => {
    return axios({
      method: 'get',
      url: '/api/getMyEvaluationQuestions',
    });
  }
  
  setSettingsAsComplete = () => {
    let updateSettings = this.props.updateSettings
    api({
      method: 'post',
      url: '/api/setSettingsAsComplete',
    }).then(results => {
      console.log("settings are complete", results);
      updateSettings(results.data);
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getSchools(), this.getFocusAreas(), this.getPrograms(), this.getEvaluationQuestions(), this.getReflectionQuestions()])
    .then(axios.spread(function (schools, focusAreas, programs, evaluationQuestions, reflectionQuestions) {
      that.setState(state => ((state.db.schools = schools.data, state)));
      that.setState(state => ((state.db.focusAreas = focusAreas.data, state)));
      that.setState(state => ((state.db.programs = programs.data, state)));
      that.setState(state => ((state.db.evaluationQuestions = evaluationQuestions.data, state)));
      that.setState(state => ((state.db.reflectionQuestions = reflectionQuestions.data, state)));
    })).then(() => {
      if (this.state.db.schools.length > 0 && this.state.db.focusAreas.length > 0 && this.state.db.programs.length > 0 &&
        this.state.db.evaluationQuestions.length > 0 && this.state.db.reflectionQuestions.length > 0)
          this.setSettingsAsComplete();
      
    });
  }

  render() {
    let completion = 0;
    if (this.props.settings.is_complete)
      completion = 100;
    else {  
    
      if (this.props.settings.short_name)
        completion += 17;
      
      if (this.state.db.schools.length > 0)
        completion += 17;
      
      if (this.state.db.focusAreas.length > 0)
        completion += 17;
        
      if (this.state.db.programs.length > 0)
      completion += 17;
      
      if (this.state.db.evaluationQuestions.length > 0)
      completion += 17;
      
      if (this.state.db.reflectionQuestions.length > 0)
      completion += 17;
    }
    
    
    return (
      <div className="side-page">
      {completion < 100 ? 
      <div className="progressBar">
      <div className="progressExplanation">
        <h2>Admintern Dashboard</h2>
        Welcome to the Admintern Client Dashboard.  You must complete certain sections before the site is ready for students and partners to use the tool.  To the right you will see the Databases you still need to populate.  Once these databases are populated, the tool will be ready for use.  If you have anyquestion, please contact the admintern admin for assistance. 
      </div>
      <Progress percent={completion} type="circle"
      theme={{
        active: {
          color: "#20416c"
        }
      }}
        />
       <div className="progressButtons">
      {this.state.db.schools.length === 0 ? <Button variant="contained" color="primary" href="/admin/schools">Add schools</Button> : null}
      {this.state.db.focusAreas.length === 0 ? <Button variant="contained" color="primary" href="/admin/focusAreas">Add focus areas</Button> : null}
      {this.state.db.programs.length === 0 ? <Button variant="contained" color="primary" href="/admin/programs">Add programs</Button> : null}
      {this.state.db.evaluationQuestions.length === 0 ? <Button variant="contained" color="primary" href="/admin/evaluationQuestions">Add evaluation questions</Button> : null}
      {this.state.db.reflectionQuestions.length === 0 ? <Button variant="contained" color="primary" href="/admin/reflectionQuestions">Add reflection questions</Button> : null}
      </div>
      </div>
      :
        <div className="basic_form">
        <Link to="businessReport/">Business Report</Link><br />
        <Link to="studentReport/">Student Report</Link><br />
        <Link to="internship_status_report/">Work-Based Learning Report</Link><br />
        <Link to="available_opportunities_report/">Opportunities Report</Link><br />
        <Link to="application_count/">Student Application Count</Link><br />

        </div>
      }
    </div>
    )
  }
}

export default connect(mapStateToProps, actions)(ConnectedAdminDashboard);
