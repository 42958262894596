import React, { Component} from "react";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


export default class OpenMap extends Component {

  state = {
    lat: 51.505,
    lng: -0.09,
    zoom: 13,
  }

  render() {

    let icon = L.icon({
     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
     iconUrl: require('leaflet/dist/images/marker-icon.png'),
     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
   })

    const position = [this.props.location.lat, this.props.location.lon]

    return (

      <Map center={position} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      <Marker position={position} icon={icon}>
          <Popup>
            <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.location.lat},${this.props.location.lon}`}>{this.props.location.street}<br /> {this.props.location.city} {this.props.location.state} {this.props.location.zip}</a>
          </Popup>
        </Marker>
      </Map>
    )
  }
}




//
//
// <Map center={this.props.position} zoom={13}>
//   <TileLayer
//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//   />
//   <Marker position={this.props.position}>
//     <Popup><a href={"https://www.google.com/maps/place/"+this.props.street+"+"+this.props.city+",+"+this.props.state+"+"+this.props.zip}>
//     {this.props.street}<br />{this.props.city} {this.props.state} {this.props.zip}
//     </a></Popup>
//   </Marker>
// </Map>
