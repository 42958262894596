import React, { Component } from 'react';
import axios from 'axios'
import {api} from "../../_helpers"
import {NotificationManager} from 'react-notifications';
import Switch from '@material-ui/core/Switch';
import ld from 'lodash';
import moment from 'moment';

export default class BusinessHoursLog extends Component {

  state = {
    total_hours : 0,
    accepted_hours : 0,
    db: {
      project : null,
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getInternData = () => {
    return axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.project_id,
      }
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getInternData()])
    .then(axios.spread(function (project) {
      that.setState(state => ((state.db.project =  project.data, state)));
      that.setState({total_hours : project.data.reportData.total_hours, accepted_hours : project.data.reportData.accepted_hours})
    }));
  }


  business_setApproval = (hours_id) => {
    let item = ld.find(this.state.db.project.hours, {_id : hours_id});
    let new_state = 0;

    console.log(item);

    if (item.business_approved === 1)
      new_state = -1;
    else
      new_state = 1;

    let json = {
      project_id : this.props.match.params.project_id,
      hours_id : hours_id,
      business_approved : new_state
    }

    let status = 'approved';
    if (item.business_approved === -1)
      status = 'disapproved';

    api({
      method: 'post',
      url: '/api/project_update_hoursApproval',
      data: {
        json
      }
    }).then((response) => {
      NotificationManager.success('Hours successfully ' + status + ' .', 'Hours Update Successfull');
      let hours_item = ld.find(this.state.db.project.hours, {_id : hours_id});
      hours_item.business_approved = json.business_approved;

      let new_total = this.state.accepted_hours;
      if (json.business_approved === 1)
        new_total += hours_item.total_hours;
      else
        new_total -= hours_item.total_hours;

      this.setState(this.state)
      this.setState({ accepted_hours : new_total})
    });
  }

  render() {
    if (this.state.db.project == null)
      return null

    return (
      <div className="side-page">
        <div className="page_header"><h1>Intern Hours Log</h1> </div>

        <div className="row">
          <div className="col-12 table-responsive-sm business_evaluation_view">

            <div className="row">
              <div className="col-6"><h3>Opportunity: {this.state.db.project.opportunity_id_fk.opportunity_title}</h3></div>
              <div className="col-6"><h3>Student: {this.state.db.project.student_id_fk.first_name} {this.state.db.project.student_id_fk.last_name}</h3></div>
            </div>

            <div className="row">
              <div className="col-6"><h3>Total Completed Hours: {this.state.accepted_hours}</h3></div>
              <div className="col-6"><h3>Total Incomplete Hours: {this.state.total_hours - this.state.accepted_hours}</h3></div>
            </div>

            <table className="table table-striped table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col" width="25%">Start</th>
                  <th scope="col" width="25%">End</th>
                  <th scope="col" width="25%">Total</th>
                  <th scope="col" width="25%">Accepted</th>
                </tr>
              </thead>
              <tbody>
              {this.state.db.project.hours.map (hours => (
                <tr key="hours._id">
                  <td>{moment(hours.start_date).format("MM/DD/YYYY")}</td>
                  <td>{moment(hours.end_date).format("MM/DD/YYYY")}</td>
                  <td>{hours.total_hours}</td>
                  <td>
                    <Switch
                      checked={hours.business_approved === 1 ? true : false}
                      onChange={() => this.business_setApproval(hours._id)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </td>
                </tr>
              ))}

              </tbody>
            </table>


          </div>
        </div>
      </div>
    )
  }
}
