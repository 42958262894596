import React, { Component } from 'react';
import axios from 'axios'
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import SchoolForm from './SchoolForm';
import swal from 'sweetalert';
import _ from 'underscore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {reorder, getListStyle} from "../Shared/DragDrop"

const actions = {
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

class SchoolList extends Component {

  state = {
      schools: [],
      reordered: false,
      viewAddNew: false
  }

  componentDidMount() {
   this.makeCall()
  }

  makeCall = () => {
    axios({
      method: 'get',
      url: '/api/getSchools',
    }).then(results => {
      this.setState({schools: results.data})
    });
  }

  toggleNew = (requery) => {
    console.log("clicked")
    this.setState({viewAddNew: !this.state.viewAddNew, selectedUser: null});
    if (requery)
      this.makeCall();
  }
  
  edit = (user) => {
    this.setState({selectedUser: user, viewAddNew: true})
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, this.rerunSearch);
  };
  
  deleteConfirm = (user) => {
    let deleteSchool = this.deleteSchool;
    let message = "You are going to delete " + user.name;
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteSchool(user._id);
      } 
    });
  }
  
  deleteSchool = (id) => {
    let link = '/api/deleteSchool';
    let message = 'School successfully deleted.';
    let rerunSearch = this.makeCall;
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            rerunSearch();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }
  
  onDragEnd = (result) => {
    console.log("this is the result: ", result)
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const schools = reorder(
    this.state.schools,
    result.source.index,
    result.destination.index
  );
  console.log("this is the items:", schools)
  if (!_.isEqual(schools, this.state.schools)){
    console.log("reordered");
    this.setState({
      schools, reordered: true
    });
  }
  
}

saveReorder = () => {
  let json = this.state.schools;
  let link = '/api/reorderSchools';
  let message = 'Reorder Successfull.';
  _.each(json, (j,k) => {
    j.ordinal = ++k
  });
  console.log("new json:", json);
  api({
      method: 'post',
      url: link,
      data: {
        json
      }
    }).then((response) => {
      console.log("this is the response:", response)
      this.setState({reordered: false})
        if (response.data === "success"){
          NotificationManager.success(message, 'Success');
        }
        else {
          NotificationManager.warning("Something went wrong", 'Uh Oh');
        }
      })
      
}


   render() {

     return (
       <div className="side-page">
         <div className="basic_form">

          <div className="page_header">
             <h1>Manage Schools
             {this.state.reordered ?
               <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder} >
                <FontAwesomeIcon icon={["fas", "save"]}  />Save Reorder
                </Button> :
               <span className="push-right withExplanation" onClick={this.toggleNew} >
                {!this.state.viewAddNew ? 
                  <FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
                  <FontAwesomeIcon icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> }
              </span>
             }
             </h1>
             <div className="formExplanation">Click the + to add schools to your system</div>
             </div>
             
             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                 {!this.state.viewAddNew ? 
                   this.state.schools.length > 0 ?
                     <table className="table table-striped table-hover business-list-table">
                       <thead className="thead-light">
                         <tr>
                            <th width="30px"></th>
                            <th scope="col" width="99%">School</th>
                            <th scope="col" width="30px">Delete</th>
                         </tr>
                       </thead>
                       <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="components">
                          {(provided, snapshot) => (
                            <tbody className="basic_form" {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}>
                              {this.state.schools.map((school, index) => (
                                <Draggable key={school._id} draggableId={school._id} index={index}>
                                {(provided, snapshot) => (
                                  <SchoolRow innerRef={provided.innerRef} obj={school} provided={provided}
                                  deleteConfirm={this.deleteConfirm}
                                  edit={this.edit}
                                  dragHandle={<span {...provided.dragHandleProps}><FontAwesomeIcon icon={["fas", "bars"]} className="draghandle"  /></span>} />
                                  )}
                                  </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  </tbody>
                                  )}  
                        </Droppable>
                        </DragDropContext>
                     </table>
                     : "You have no schools in your system yet.  Please add some with the plus button above"
                   : <SchoolForm toggleBack={this.toggleNew} selectedUser={this.state.selectedUser}  /> }
                 </div>
               </div>
             </div>


         </div>
       </div>
     )
   }
}

export default connect(mapStateToProps, actions)(SchoolList);

class SchoolRow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
     <tr {...provided.draggableProps}
     ref={innerRef} key={this.props.obj._id}>
       <td>{this.props.dragHandle}</td>
       <td><span className="makeitalink" onClick={() => {this.props.edit(this.props.obj)}}>{this.props.obj.name}</span></td>
       <td><span className="table_icons" onClick={()=>this.props.deleteConfirm(this.props.obj)}>
       <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
     </span></td>
     </tr>
    )
  }
}



