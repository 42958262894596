import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SingleSelect, MultiSelect} from '../../_formhelpers'
import {ApproveIcon, MasqueradeIcon, DeleteUserIcon, printAgreementBtn} from './_shared';
import {ExportCSV} from '../Shared/ExcelExport';

export default class BusinessReport extends Component {

  state = {
    schools: [],
    focus_areas: [],
    list: [],
    approved: "All",
    db: {
      schools: [],
      focus_areas: []
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getAllUsers = () => {
    return axios({
      method: 'post',
      url: '/api/filterUsers',
      data : {
        role: "Business",
        schools: this.state.schools.length > 0 ? this.state.schools : null,
        focus_areas: this.state.focus_areas.length > 0 ? this.state.focus_areas : null,
        approved: this.state.approved
      }
    })
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: '/api/getSchools',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: '/api/getFocusAreas',
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getAllUsers(), this.getSchools(), this.getFocusAreas()])
    .then(axios.spread(function (users, schools, focus_areas) {
      that.setState(state => ((state.list = users.data, state)));
      that.setState(state => ((state.db.schools = schools.data, state)));
      that.setState(state => ((state.db.focus_areas = focus_areas.data, state)));
    }));
  }

  updateList = (list) => {
    this.setState({list: list})
  }

  printUser = (user) => {
    // console.log("printing: ", user)
    if (!user.createdAt){
      user.createdAt = moment();
      }

    //let obj = user.files.find(obj => obj.description === "profile_pic_cropped");
    let obj = user.profile_pic_id_fk;

    return (
      <tr key={user.id}>
        <td><Link to={'/profile/view/' + user._id}>
        {obj ?
          <Avatar className={"business_avatar sm"} src={obj.path}  /> :
            <Avatar className="business_avatar sm"><FontAwesomeIcon icon={["fal", "building"]}/></Avatar> }
            </Link></td>
            <td><Link to={'/profile/view/' + user._id}>
              {user.business_name}</Link></td>

        <td>
          {moment().format("YYYY") - moment(user.createdAt).format("YYYY") + 1}
        </td>
        <td>
          <ApproveIcon userId={user._id} approved={user.approved} callBack={this.rerunSearch} />
        </td>
        <td>
          {printAgreementBtn(user.agreement_file_id_fk)}
        </td>
        <td>
          <MasqueradeIcon userId={user._id}  />
        </td>
        <td>
          <DeleteUserIcon user={user} callBack={this.local_removeUser} />
        </td>
      </tr>
    )
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    let that = this;
    axios.all([this.getAllUsers()])
    .then(axios.spread(function (users) {
      that.setState(state => ((state.list = users.data, state)));
    }));
  }

  local_removeUser = (_id) => {
    this.rerunSearch();
  }


   render() {

     return (
       <div className="side-page">
         <div className="basic_form">

           <div className="page_header with_search no_results"><h1>Participating Partners</h1>
           <ExportCSV csvData={this.state.list} fields={["business_name", "contact_name", "contact_email", "contact_phone"]} fileName="participating_businesses" />
           </div>
           <div className='filter-on'>
            <MultiSelect label="Schools" name="schools" onChange={this.handleChange}
             value={this.state.schools}
             options={this.state.db.schools.map(s =>
               {
                 return {value: s.name, label: s.name};
               })}
           />
           <MultiSelect label="Focus Areas" name="focus_areas" onChange={this.handleChange}
            value={this.state.focus_areas}
            options={this.state.db.focus_areas.map(s =>
              {
                return {value: s.name, label: s.name};
              })}
          />
          <SingleSelect label="Approved" name="approved" onChange={this.handleChange} value={this.state.approved}
          options={[{value: "All", label: "All Partners"},{value: "Approved", label: "Approved Partners"}, {value: "Unapproved", label: "Unapproved Partners"}]} />
           </div>
            {this.state.list.length > 0 ?

             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                   <table className="table table-striped table-hover business-list-table">
                     <thead className="thead-light">
                       <tr>
                         <th scope="col"></th>
                         <th scope="col" width="45%">Business</th>
                         <th scope="col" width="15%">Years Active</th>
                         <th scope="col" width="10%">Approve</th>
                         <th scope="col" width="10%">Agreement</th>
                         <th scope="col" width="10%">Masquerade</th>
                         <th scope="col" width="10%">Delete</th>
                       </tr>
                     </thead>
                     <tbody>
                       {this.state.list.map(user => (
                         this.printUser(user)
                       ))}
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>

             : <div className="search_above">No Businesses Match that Filter</div> }


         </div>
       </div>
     )
   }
}
