import React, { Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

export default class InternshipStatusLine extends Component {


  render_status = (datapoint) => {
    let data = this.props.project.reportData;

    let dot = <FontAwesomeIcon icon={["far", "circle"]} />

    switch (datapoint) {
      case "activity_log":
        if (data.activity_log_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.activity_log_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
      break;

      case "reflections":
        if (data.reflection_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.reflection_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
        else
          dot = <span>{data.reflection_count}</span>
      break;

      case "final_project":
        if (data.final_project_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.final_project_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
      break;

      case "final_summary":
        if (data.final_summary_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.final_summary_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
      break;

      case "evals":
        dot = this.props.project.business_evaluations.length;
      break;

      default:
        dot = ""

    }

    return dot;

  }

  render() {
    let obj = this.props.project.student_id_fk.profile_pic_id_fk;

    return (

      <tr>
        <td>
          <Link to={'/profile/view/' + this.props.project.student_id_fk._id}>
          {obj ?
            <Avatar className={"studnet_avatar sm"} src={obj.path}  /> :
            <Avatar className="student_avatar sm"><FontAwesomeIcon icon={["fal", "graduation-cap"]}/></Avatar> }
          </Link>
        </td>


        <td><Link to={'/admin/project/' + this.props.project._id} title="View Student">{this.props.project.student_id_fk.first_name} {this.props.project.student_id_fk.last_name}</Link></td>
        <td>{this.props.project.business_id_fk.business_name}</td>
        <td className="capitalize_it">{this.props.project.business_status}</td>
        <td align="center">
          <span id="approvedHours">{this.props.project.reportData.accepted_hours} / ({this.props.project.reportData.total_hours - this.props.project.reportData.accepted_hours})</span>
        </td>
        <td align="center">{this.render_status('reflections')}</td>
        <td align="center">{this.render_status('final_project')}</td>
        <td align="center">{this.render_status('final_summary')}</td>
        <td align="center">{this.render_status('msde')}</td>
        <td align="center">{this.render_status('evals')}</td>
        <td align="center">{moment(this.props.project.updatedAt).format('MM/DD/YY')}</td>
      </tr>
    )
  }
}
