import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OpportunityTable} from './_shared.jsx';
import BootBox from '../Shared/BootBox.jsx';
import {NotificationManager} from 'react-notifications';

export default class OpportunityList extends Component {

  state = {
    db: {
      opportunities: [],
    },
    show_delete_modal: false,
    selected_opportunity : null,
  }

  componentDidMount() {
    this.makeCalls();
  }

  getOpportunities = () => {
    return axios({
      method: 'get',
      url: '../api/getOpportunities',
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getOpportunities()])
    .then(axios.spread(function (opportunities) {
      that.setState(state => ((state.db.opportunities = opportunities.data, state)));
    }));
  }

  deleteOpportunity = (id) => {
    this.setState({selected_opportunity : id, show_delete_modal : true})
  }

  confirmDelete = () => {
    console.log('delete!');

    let that = this;
    axios({
      method: 'post',
      url: '/api/deleteOpportnity',
      data: {
        id : this.state.selected_opportunity
      }
    }).then(function (response) {
      NotificationManager.success('Opportunity successfully deleted.', 'Successfull');
      that.setState({selected_opportunity : null, show_delete_modal : false}, that.makeCalls())
    });
  }

  handleClose = () => {
    this.setState({selected_opportunity : null, show_delete_modal : false})
  }

  render() {
    return (
      <div className="side-page">
        <div className="page_header"><h1>Manage Opportunities <span className="push-right"><Link to='/opportunity/new'><FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5"/></Link></span></h1> </div>

        <div className="row">
          <div className="col-12 table-responsive-sm">
            <OpportunityTable data={this.state.db.opportunities} deleteOpportunity={this.deleteOpportunity} />

            <BootBox
              message="Are you sure you want to delete this opportunity?"
              show={this.state.show_delete_modal}
              onYesClick={this.confirmDelete}
              onNoClick={this.handleClose}
              onClose={this.handleClose}
              cancelText="Cancel"
              confirmText="Delete"
            />
          </div>
        </div>
      </div>
    )
  }
}
