import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import  OpenMap  from '../Layout/Map';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

class OpportunityView extends Component {

  state = {
    db: {},
  }

  componentDidMount() {
    this.makeCalls();
  }

  loadOpportunity = () => {
    return axios({
      method: 'get',
      url: '/api/loadOpportunity',
      params: {
        opp_id : this.props.match.params.id,
      }
    });
  }

  loadApplication = () => {
    return axios({
      method: 'get',
      url: '/api/getApplicationToOpportunity',
      params: {
        opp_id : this.props.match.params.id,
      }
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.loadOpportunity(), this.loadApplication() ])
    .then(axios.spread(function (opp, app) {
      that.setState(state => ((state.db.opportunity = opp.data, state)));
      that.setState(state => ((state.db.application = app.data, state)));
    }));
  }

  apply_for_opportunity = () => {
    this.props.history.push("/opportunity/view/" + this.props.match.params.id + "/apply");
  }

  view_application = (id) => {
    this.props.history.push("/application/view/" + id);
  }

  student_buttons = () => {
    let app = (typeof(this.state.db.application) == 'undefined' || Object.keys(this.state.db.application).length === 0) ? null : this.state.db.application[0];
    let opp = this.state.db.opportunity;

    let available = opp.available_slots - opp.reportData.accepted_count;
    console.log(available);

    let button_return;
    if (this.props.user.role === "Student"){

    if (app) {
      button_return = (
        <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={(e) => this.view_application(app._id)}>
          View Application
        </Button>)
    }
    else if (available <= 2 && available > 0 && parseInt(opp.available_slots) > 2) {
      button_return = (
        <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.apply_for_opportunity}>
        Click to Apply <br />Only {available} Spots Open
        </Button>)
    }
    else if (available > 0){
      button_return = (
        <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.apply_for_opportunity}>
        Click to Apply
        </Button>)
    }
    else {
      button_return = (<React.Fragment>
        <b>This Opportunity is Full</b>
      </React.Fragment>)
    }

     return(
       button_return
     )
   }

  }


  render() {
    const opportunity = this.state.db.opportunity;
    // const application = this.props.application;

    if (typeof(opportunity) == 'undefined')
      return null;

    return (
      <div className="side-page">
        <div className="page_header">
          <div className="row">
            <div className="col-md-10">
              <FontAwesomeIcon icon={["fal", "building"]} className="business_icon_or_logo"/>
              <div>
                <h1>{opportunity.opportunity_title}</h1>
                <h3><Link to={'/profile/view/' + opportunity.business_id_fk._id} title="View Opportunity">{opportunity.business_id_fk.business_name}</Link></h3>

              </div>
            </div>
            <div className="col-md-2 apply_container">

              {(this.props.user.role === "Business") ? ( null ) : ( this.student_buttons()) }

            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-4 opportunity_view_side d-none d-sm-none d-md-block">
            {opportunity.location.street !== "" ?
            <OpenMap location={opportunity.location} />
              : null }

            <br /><FontAwesomeIcon icon={["fal", "user"]} className="little-icons" /> {opportunity.business_id_fk.business_name}
            <br /><FontAwesomeIcon icon={["fal", "envelope"]} className="little-icons" /><a href={"mailto:"+opportunity.business_id_fk.contact_email}>{opportunity.business_id_fk.contact_email}</a>
            <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+opportunity.business_id_fk.contact_phone}>{opportunity.business_id_fk.contact_phone}</a>

          </div>
          <div className="col-md-8 opportunity_view">
            <div className="top_section row">
              <div className="col-md-4">
                <FontAwesomeIcon icon={["fal", "clock"]} className="big-icons" /> {opportunity.opportunity_type}
              </div>
              <div className="col-md-6">
                <FontAwesomeIcon icon={["fal", "calendar-alt"]} className="big-icons" /> {moment(opportunity.employment_start).format("MM/DD/YYYY")} - {moment(opportunity.employment_stop).format("MM/DD/YYYY")}
              </div>
              <div className="col-md-2">
                <FontAwesomeIcon icon={["fal", "usd-circle"]} className="big-icons" /> {opportunity.paidUnpaid}
              </div>
            </div>
            <h1>Short Description</h1>
            {opportunity.short_description}
            <h1>Required Skills</h1>
            <div dangerouslySetInnerHTML={{__html: opportunity.skills_required}} />
            <h1>Description</h1>
            <div dangerouslySetInnerHTML={{__html: opportunity.full_description}} />
          </div>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(OpportunityView);





/*

<OpenMap position={[opportunity.lat, opportunity.lon]}
  street={opportunity.location.street}
  city={opportunity.location.city}
  state={opportunity.location.state}
  zip={opportunity.location.zip}
/>


*/
