import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios'

export default class OpportunityRow extends Component {

  state = {
    newApplications: []
  }

  componentDidMount(){
    console.log("mounted", moment(this.props.opportunity.employment_start).isBefore());
    if (moment(this.props.opportunity.employment_start).isBefore()) { // make sure this isn't old to save calls
    let that = this;
    axios({
      method: 'get',
      url: '/api/getNewApplications',
      params: {
        opp_id : this.props.opportunity._id,
      }
    }).then(function(response) {
      console.log("get new applications:", response);
      if (response.data){
        // remove Rescinded applications
        that.setState({newApplications: response.data});
      }
      else {
        console.log("application error");
      }
    })
  }
  }

  app_count = (count) => {
    let badge = (<span className="font_awesome_badge">{count}</span>)

    if (count === 0)
      badge = (<span></span>);

    return (
      badge
    )
  }

  render () {
    const opp = this.props.opportunity;

    /*let application_count = 0;
    if (typeof opp.applications !== 'undefined') {
      application_count = opp.applications
    }*/

    if (opp == null) {
      return null
    }

    return (
      <tr key={opp._id}>
        <td><Link to={'/opportunity/applicants/' + opp._id}>{opp.opportunity_title}</Link></td>
        <td>{moment(opp.employment_start).format("MM/DD/YYYY")} - {moment(opp.employment_stop).format("MM/DD/YYYY")}</td>
        <td>{parseInt(opp.available_slots) - parseInt(opp.reportData.accepted_count)}</td>
        <td>{parseInt(opp.reportData.applicant_count)}</td>
        <td className="table-actions">
          <Link to={'/opportunity/applicants/' + opp._id} title="Applicants">
            <FontAwesomeIcon icon={["far", "users"]} />
            {this.app_count(this.state.newApplications.length)}
          </Link>
          <Link to={'/opportunity/view/' + opp._id} title="View Opportunity">
            <FontAwesomeIcon icon={["fal", "eye"]} />
          </Link>
          <Link to={'/opportunity/edit/' + opp._id} title='Edit Opportunity'><FontAwesomeIcon icon={["far", "edit"]}/></Link>
          <Link to={'/opportunity/duplicate/' + opp._id} title="Duplicate Opportunity"><FontAwesomeIcon icon={["far", "copy"]}/></Link>
          <Link to='#' title="Delete" onClick={(e) => this.props.deleteOpportunity(opp._id)}><FontAwesomeIcon icon={["far", "trash-alt"]}/></Link>
        </td>
      </tr>
    )
  }
}
