import React, { Component } from 'react';
import axios from 'axios'
import ld from 'lodash';
import moment from 'moment';

export default class BusinessEvaluationView extends Component {

  state = {
    db: {
      project : null,
      evaluation : null,
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getInternData = () => {
    return axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.project_id,
      }
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getInternData()])
    .then(axios.spread(function (project) {
      let evaluation = ld.find(project.data.business_evaluations, {_id : that.props.match.params.id});
      that.setState(state => ((state.db.evaluation =  evaluation, state)));
      that.setState(state => ((state.db.project =  project.data, state)));
    }));
  }

  render() {
    if (this.state.db.project == null || this.state.db.evaluation == null)
      return null

    return (
      <div className="side-page">
        <div className="page_header"><h1>Intern Evaluation Form</h1> </div>

        <div className="row">
          <div className="col-12 table-responsive-sm business_evaluation_view">

            <h3>Work-Based Learning Details</h3>
              <div className="row">
                <div className="col-6"><label>Trainee:</label> {this.state.db.project.student_id_fk.first_name} {this.state.db.project.student_id_fk.last_name}</div>
                <div className="col-6"><label>Employed By:</label> {this.state.db.project.business_id_fk.business_name}</div>
              </div>

              <div className="row">
                <div className="col-6"><label>Completion Date:</label> {moment(this.state.db.evaluation.evaluation_date).format("MM/DD/YYYY")}</div>
                <div className="col-6"><label>Supervisor:</label> {this.state.db.evaluation.supervisor}</div>
              </div>

              <div className="row">
                <div className="col-6"><label>Coordinator:</label> {this.state.db.evaluation.coordinator}</div>
                <div className="col-6"><label>Phone Number:</label> {this.state.db.evaluation.contact_phone}</div>
              </div>

              <div className="row">
                <div className="col-6"><label>Term:</label> {this.state.db.evaluation.term}</div>
                <div className="col-6"></div>
              </div>

            <h3 className="mt-3">Performance on the Job</h3>
            {this.state.db.evaluation.ratings.map(question => (
              <div>
                {question.question_text}
                <br /><label>Response:</label> {question.rating}
              </div>
            ))}

            <h3 className="mt-3">Recomendations and Comments</h3>
            <div dangerouslySetInnerHTML={{__html: this.state.db.evaluation.comments}}></div>


          </div>
        </div>
      </div>
    )
  }
}
