import React, { Component} from "react";

export default class Project_Questions extends Component {

  render() {

    return (
      <div>
      <h3>Reflection Questions</h3>
      {  this.props.project.reflections.map(reflection => (
        <>
        <div className="reflectionQuestionText">{reflection.question_text}</div>
        <div className="reflectionQuestionAnswer">
          {reflection.status === "locked" ? (
            "Question currently locked."
          ) : (
            reflection.status === 'unlocked' ? (
              "Question is available, but currently unanswered."
            ) : (
              reflection.status === 'complete' ? (
                <div dangerouslySetInnerHTML={{__html : reflection.answer_text}}></div>
              ) : (null)
            )
          )}
        </div>
        </>
      )) }
      </div>

    )
  }
}
