import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {setUser, updateSettings } from '../../ducks';
import { connect } from 'react-redux';
import SiteLogo from "../Layout/SiteLogo"
import {PhoneMask} from '../../_masks';

const actions = {
  setUser,
  updateSettings
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
    
  };
};

class SettingsForm extends Component {

  state = {
    disabled: true,
    shrink: {
      phone_number: (this.props.settings.phone_number) ? true : false
    }
  }

  componentDidMount() {
    if (this.props.settings) {
      this.setState({...this.props.settings, disabled: false});
    }
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, () => {
      if (this.state.logo && this.state.short_name && this.state.full_name)
        this.setState({disabled: false})
      else
        this.setState({disabled: true})
    });
  };
  
   getFile = (file) => {
     console.log("file", file);
     let link = "http://localhost:5008/";
     if (process.env.NODE_ENV !== "development")
       link = "/";
       
    file.path = link + file.path;
     this.setState({logo: file}, () => {
       if (this.state.logo && this.state.short_name && this.state.full_name)
        this.setState({disabled: false});
      else
        this.setState({disabled: true});
      })
   }
   
   handleMaskChange = event => {
     //let that = this;
     let name = event.target.name;
     if (event.target.value.length > 0)
         this.setState(state => ((state.shrink[name] = true, state)));
     else
       this.setState(state => ((state.shrink[name] = false, state)));
 
     this.setState({ [event.target.name] : event.target.value });
   };
 
   handleMaskFocus = event => {
     let name = event.target.name;
     this.setState(state => ((state.shrink[name] = true, state)));
   };
 
   handleMaskBlur = event => {
     let name = event.target.name;
     if (event.target.value.length > 0)
       this.setState(state => ((state.shrink[name] = true, state)));
     else
       this.setState(state => ((state.shrink[name] = false, state)));
   };
  
  saveSettings = (e) => {
    let json = this.state;
    json.file = this.state.logo._id
    let link = '/api/saveSettings';
    let message = 'Site Settings Saved';
    let updateSettings = this.props.updateSettings;
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then((response) => {
        console.log("this is the response:", response)
        
          if (response.data !== "fail"){
            NotificationManager.success(message, 'Success');
            updateSettings(response.data)
            this.props.history.push("/default")
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.saveSettings()}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="short_name" label="Sort Name (i.e. AACPS)" variant="outlined"
        onChange={this.handleChange} value={this.state.short_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="full_name" label="Full Name (i.e. Anne Arundle County Public Schools)" variant="outlined"
        onChange={this.handleChange} value={this.state.full_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="phone_number" label="Phone Number (000)000-0000)" variant="outlined"
        onChange={this.handleMaskChange} value={this.state.phone_number}
        onFocus={this.handleMaskFocus}
        onBlur={this.handleMaskBlur}
        placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        InputLabelProps={{ shrink: this.state.shrink.phone_number }}
        />
        
        <SiteLogo file={this.state.logo} type="site_logo_full" description="site_logo_full" key="client_id_fk" getFile={this.getFile} />
        
        <TextValidator
        name="default_password" label="Default Password" variant="outlined"
        onChange={this.handleChange} value={this.state.default_password}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <TextValidator
        name="facebook" label="Facebook" variant="outlined"
        onChange={this.handleChange} value={this.state.facebook}
        className="inputs"
        />
        
        <TextValidator
        name="twitter" label="Twitter" variant="outlined"
        onChange={this.handleChange} value={this.state.twitter}
        className="inputs"
        />
        
        <TextValidator
        name="snapchat" label="Snapchat" variant="outlined"
        onChange={this.handleChange} value={this.state.snapchat}
        className="inputs"
        />
        
        <TextValidator
        name="instagram" label="Instagram" variant="outlined"
        onChange={this.handleChange} value={this.state.instagram}
        className="inputs"
        />
        
        <div className="mobile-center-button">
        <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
          Edit Site Settings
        </Button>

        </div>
        
       </ValidatorForm>
     )
   }
}

export default connect(mapStateToProps, actions)(SettingsForm);
