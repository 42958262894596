import React, { Component } from 'react';
import axios from 'axios'
import moment from 'moment';
import ld from 'lodash';
import _ from 'underscore';
import swal from 'sweetalert';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// import Reflections from './Reflections.jsx';
import HoursLogAdd from './HoursLog_Add.jsx';
// import ProjectSummary from './ProjectSummary.jsx';
import { Icons } from './icons';

export default class HourLog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page : "hours",
      hours: [],
      project : null,
    }
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    var that = this;
    axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    }).then(function (response) {
      let rawHours = response.data.hours;
      response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc')

      that.setState(state => ((state.project = response.data, state)));
    });
  }

  delete_hours = (id) => {
    let that = this;
    let jsonData = {
      hours_log_id  : id,
      project_id : this.props.match.params.id,
    };

    swal({
      title: "Are you sure?",
      text: "You are about to delete this hour log entry. It cannot be recovered. The text entry will be copied to the clipboard in case you are trying to adjust the hours and wish to reuse the activity log.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        let hours_item = _.find(this.state.project.hours, {_id : id});
        navigator.clipboard.writeText(hours_item.text_log);

        axios.post("/api/project_deleteHoursLogEntry", jsonData, {}).then(res => {
          that.makeCalls()
        });
      }
    });
  }

  render() {
    const project = this.state.project;

    if (project == null) {
      return null
    }

    return (
      <div className="side-page">
        <div className="basic_form">

          <div className="page_header">
            <div className="row">
              <div className="col-md-10">
                <div>
                  <h1 className="bigger">{this.state.project.opportunity_id_fk.opportunity_title}</h1>
                </div>
              </div>
              <div className="col-md-2 apply_container">
                <Icons project={this.props.match.params.id}/>
              </div>
            </div>
          </div>
          <div className="sub-header">Hours Log</div>
          <div className="row">
            <div className="col-md-5">
              {this.state.project.business_status === "terminated" ? (
                <h4 className="hours_terminated">
                  <FontAwesomeIcon icon={["fal", "exclamation-circle"]} /><br />
                  This work-based learning opportunity has been terminated by the business. Additional hours cannot be added at this time.
                </h4>
              ) : (
                <HoursLogAdd
                  projectId={this.state.project._id}
                  saveCallback={this.makeCalls}
                />
              )}
            </div>
            <div className="col-md-7" key={this.state.project._id}>
              {  project.hours.reverse().map(hour => (
                <div className="hour-log" key={hour._id}>
                  <div className="hour-log-date">
                    <div className="hour-log-date-cnt">{moment(hour.start_date).format("MM/DD/YYYY")}</div>
                  </div>
                  <div className="hour-log-flex">
                    <div className="hour-log-hours">{hour.total_hours}</div>
                    <div className="hour-log-log" dangerouslySetInnerHTML={{__html:hour.text_log}}></div>
                    {hour.business_approved === 0 ? (
                      <div className="hour-log-delete">
                        <FontAwesomeIcon icon={["fal", "trash-alt"]} title="Delete Entry" onClick={() => {this.delete_hours(hour._id)}}/>
                      </div>
                    ) : (null)}
                  </div>
                </div>
              )) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
