import React, { Component } from 'react';
import axios from 'axios'
import {ProjectTable} from './_ProjectTable';
import _ from 'underscore';

export default class StudentProjects extends Component {

  state = {
    db: {
      projects: [],
      totalHours: 0
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getProjectsByStudent = () => {
    return axios({
      method: 'get',
      url: '../api/getProjectsByStudent',
    });
  }

  makeCalls = () => {
    var that = this;
    var total = 0;
    axios.all([this.getProjectsByStudent()])
    .then(axios.spread(function (projects) {
      _.each(projects.data, (p,k) => {
        total += p.reportData.total_hours
      })
      that.setState({totalHours: total})
      that.setState(state => ((state.db.projects = projects.data, state)));
    }));
  }


   render() {
     const projects = this.state.db.projects;

 //     if (projects == null) {
 //       return null
 //     }

     return (
       <div className="side-page">
         <div className="basic_form">

           <div className="page_header with_search"><h1>Work-Based Learning</h1> {this.state.totalHours > 1 ? <span className="headerTotalHours">{this.state.totalHours} total hours</span> : null }</div>

           <ProjectTable projects={projects} />

         </div>
       </div>
     )
   }
}
