import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {setUser } from '../../ducks';
import CropModal from '../Shared/CropModal';
import {NotificationManager} from 'react-notifications';

const mapStateToProps = (state, props) => {
  return {
    user_id: state.auth._id,
    role: state.auth.user.role,
    authenticated: state.auth.isAuthenticated,
    files: state.files
  };
};

const actions = {
  setUser
}

class ConnectedAdminNav extends Component {

  render() {
    return (
        <>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/admin/user_search'>User Search</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
              <Link className="nav-link" to='/admin/dashboard'>Reports</Link>
              </li>
              {this.props.role === "Admin" ?
              <li className="nav-item dropdown">
                
                <button className="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Site Admin
                </button>
                
                <div className="dropdown-menu" aria-labelledby="navbarDropdown" onClick={this.props.closeMenu}>
                  <Link className="dropdown-item" to="/admin/siteSettings">Site Settings</Link>
                  <Link className="dropdown-item" to="/admin/schools">Schools</Link>
                  <Link className="dropdown-item" to="/admin/focusAreas">Focus Areas</Link>
                  <Link className="dropdown-item" to="/admin/programs">Programs</Link>
                  <Link className="dropdown-item" to="/admin/reflectionQuestions">Reflection Questions</Link>
                  <Link className="dropdown-item" to="/admin/evaluationQuestions">Evaluation Questions</Link>
                  
                  <Link className="dropdown-item" to='/admin/resources/student'>Student Resources</Link>
                  <Link className="dropdown-item" to='/admin/resources/business'>Business Resources</Link>
                  <Link to='/admins' className="dropdown-item">Teachers</Link>
                </div>
              </li>
              : null }
         </>
      )
  }
}

class BusinessNav extends Component {

  render() {
    return (
        <>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/opportunity/new'>Create Opportunity</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/opportunity/all'>Manage Opportunities</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/interns/viewall'>Current Interns</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/resources'>Resources</Link>
              </li>
         </>
      )
  }
}

class StudentNav extends Component {

  render() {
    return (
        <>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/opportunity/browse'>Browse</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/applications/list'>Applications</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/projects/viewall'>My Jobs</Link>
              </li>
              <li className="nav-item" onClick={this.props.closeMenu}>
                <Link className="nav-link" to='/resources'>Resources</Link>
              </li>
         </>
      )
  }
}

class OutsideNav extends Component {

  render() {
    return (
      <>
        {/*<li className="nav-item" onClick={this.props.closeMenu}>
          <Link className="nav-link" to='/business_list'>Participating Businesses</Link>
        </li>*/}
        <li className="nav-item" onClick={this.props.closeMenu}>
          <Link className="nav-link" to='/contact'>Contact</Link>
        </li>
        {/*<li className="nav-item" onClick={this.props.closeMenu}>
          <Link className="nav-link" to='/resources'>Resources</Link>
        </li>*/}
      </>
    )
  }
}

class ConnectedProfileButton extends Component {
    render() {

    var profile_link = '/profile';

    if (!this.props.authenticated)
      profile_link = '/login'

    return (
        <Link to={profile_link} className="dropdown-item"><FontAwesomeIcon icon={["fas", "user-circle"]}/> Profile</Link>
    )
  }
}

const ProfileButton = connect(mapStateToProps)(ConnectedProfileButton);


class ConnectedInnerNav extends Component {

  logoutUser = () => {
    axios({
      method: 'get',
      url: '/auth/logout',
    }).then(function (response) {
      console.log("this is the response:", response);
       if (response.data.message === "success"){
         document.location = "/";
       }
       else {
         console.log("ERROR!!!!");
       }
    });
  }

  state = {
    cropToolShow: false
  }

  uploadNewPic = (e) => {
      e.preventDefault();

      let that=this;
      let description = "profile_pic_full";
      let key = "user_id_fk";
      const formData = new FormData()
      formData.append('fileupload', e.target.files[0]);
      formData.append('description', description);
      formData.append('key', key);

      axios.post("/api/upload_file", formData, {
      }).then(res => {
        console.log("what's the res:", res);
        that.setState({profile_pic_path: res.data.path});
        that.setState({cropToolShow: true})

      }).catch(error => {
          NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
      });
    };


  clickUpload = (field) => {
    document.getElementById("profilePicUploadFromTop").click();
  }

  handleClose = () => {
    this.setState({cropToolShow: false});
  }

  confirmCrop = () => {
    console.log("confirmed crop");
  }

  render() {

    return (
      <>
      <li className="nav-item dropdown">
        <button className="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <TopProfilePic />
        </button>
        <div className='dropdown-menu dropdown-menu-right' aria-labelledby="navbarDropdown" onClick={this.props.closeMenu}>
          <ProfileButton></ProfileButton>
          <Link to='/password' className="dropdown-item"><FontAwesomeIcon icon={["fal", "key"]}/> Change Log In Info</Link>
          <button className="dropdown-item" onClick={this.clickUpload}>
            <FontAwesomeIcon icon={["fal", "upload"]}/>
            {this.props.files.find(obj => obj.description === "profile_pic_cropped") ? " Change" : " Upload"}
            {this.props.role === "Business" ? " Logo" : " Profile Pic"}</button>
          <button className="dropdown-item" onClick={this.logoutUser}><FontAwesomeIcon icon={["far", "sign-out-alt"]}/> Logout</button>
        </div>
      </li>

      <input
        name="profilePicUploadFromTop"
        id="profilePicUploadFromTop"
        label="Profile Pic"
        onChange={this.uploadNewPic}
        type="file"
        style={{display:"none"}}
      />

    <CropModal
        message="test crop"
        show={this.state.cropToolShow}
        onYesClick={this.confirmCrop}
        onNoClick={this.handleClose}
        onClose={this.handleClose}
        cancelText="Cancel"
        confirmText="Crop"
        image={this.state.profile_pic_path}
      />
      </>
    )
  }
}

const InnerNav = connect(mapStateToProps, actions)(ConnectedInnerNav);

class TopProfilePicConnected extends Component {

  render() {
    if (this.props.files === undefined)
      return null

    let obj = this.props.files.find(obj => obj.description === "profile_pic_cropped");

    return (
      <span className="d-none d-lg-inline">
          {obj ?
            <Avatar className="student_avatar" src={obj.path}  /> :
              <FontAwesomeIcon icon={["far", "cog"]}/>
          }
      </span>
    )
  }
}

const TopProfilePic = connect(mapStateToProps)(TopProfilePicConnected);
const AdminNav = connect(mapStateToProps)(ConnectedAdminNav);

export {AdminNav, StudentNav, BusinessNav, InnerNav, OutsideNav, ProfileButton}
