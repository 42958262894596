import React, { Component} from "react";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import axios from 'axios'

import {NotificationManager} from 'react-notifications';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  login = (e) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: '/auth/login',
      data: {
        email: this.state.email,
        password: this.state.password,
        safe: true
      }
    }).then(function (response) {
      console.log("user info:", response.data.user);
      if (response.data.message === "success"){
        document.location = "/default";
      }
      else {
        NotificationManager.error("Wrong Name/PW", 'Unsuccessful Login', 5000);
      }
    });
  }

  render () {
    return (
      <div className="login-hero">
        <Paper className="container_accounts mx-auto" elevation={1}>
          <form onSubmit={this.login}>
            <TextField
              className="inputs"
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />

            <TextField
              className="inputs"
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />

            <br />
            <Button type="submit" variant="contained" color="primary" className="btn_action">
              Login &nbsp; <FontAwesomeIcon icon={["far", "sign-in-alt"]}/>
            </Button>
            <Link to='/signup' className="switch-pages">Need To Create An Account?</Link>
          </form>

          {process.env.NODE_ENV === "development" ?
            <DevLogInButtons />
          : null }


        </Paper>
      </div>

    )
  }
}

class DevLogInButtons extends Component {

  devLogin = (email, pw) => {
    axios({
      method: 'post',
      url: '/auth/login',
      data: {
        email: email,
        password: pw,
        safe: true
      }
    }).then(function (response) {
      if (response.data.message === "success"){
          document.location = "/default";
      }
      else {
        console.log("ERROR!!!!");
      }
    });
  }

  render() {
    return (
      <div>
        <Button variant="contained" color="primary" className="btn_action" onClick={(e) => this.devLogin('admin@admintern.com', 'admin')}>SUPERADMIN</Button>
        <Button variant="contained" color="primary" className="btn_action" onClick={(e) => this.devLogin('tdiedrich@aacps.org', 'admintern1234')}>Site Admin</Button>
        <Button variant="contained" color="primary" className="btn_action" onClick={(e) => this.devLogin('brandon@gmail.com', 'abc123!!!')}>STUDENT</Button>
        <Button variant="contained" color="primary" className="btn_action" onClick={(e) => this.devLogin('pasadena@greenturtle.com', 'abc123!!!')}>BUSINESS</Button>
      </div>
    )
  }
}
