import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SingleSelect, MultiSelect} from '../../_formhelpers'
import {ExportCSV} from '../Shared/ExcelExport'

export default class ApplicationCount extends Component {

  state = {
    school_attending: [],
    affiliated_program: [],
    list: [],
    selected_year: 'All',
    db: {
      schools: [],
      programs: []
    },
    graduation_years : []
  }

  componentDidMount() {
    this.makeCalls();

    let current_year = moment().year();
    let grad_array = [];
    for (let i = -2; i < 4; i++) {
      grad_array.push(i + current_year);
    }
    grad_array.push('All');

    this.setState({graduation_years : grad_array})
    // this.setState({graduation_years : grad_array, selected_year : current_year})
  }

  getAllUsers = () => {
    return axios({
      method: 'post',
      url: '/api/filterApplicationCount',
      data : {
        role: "Student",
        school_attending: this.state.school_attending.length > 0 ? this.state.school_attending : null,
        affiliated_program: this.state.affiliated_program.length > 0 ? this.state.affiliated_program  : null,
        graduation_year: this.state.selected_year
      }
    })
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: '/api/getSchools',
    });
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: '/api/getPrograms',
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getAllUsers(), this.getSchools(), this.getPrograms()])
    .then(axios.spread(function (users, schools, programs) {
      that.setState(state => ((state.list = users.data, state)));
      that.setState(state => ((state.db.schools = schools.data, state)));
      that.setState(state => ((state.db.programs = programs.data, state)));
    }));
  }

  updateList = (list) => {
    this.setState({list: list})
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    let that = this;
    axios.all([this.getAllUsers()])
    .then(axios.spread(function (users) {
      that.setState(state => ((state.list = users.data, state)));
    }));
  }


   render() {


     return (
       <div className="side-page">
         <div className="basic_form">

           <div className="page_header with_search no_results"><h1>Student Application Count</h1>
            {<ExportCSV
              csvData={this.state.list}
              fields={["student_id_fk.first_name", "student_id_fk.last_name", "business_id_fk.business_name", "reportData.school", "reportData.print_activity_log_status", "reportData.accepted_hours", "reportData.total_hours", "reportData.print_reflection_status", "reportData.reflection_count", "reportData.print_final_project_status", "reportData.print_project_summary_status", "reportData.print_msde_status", "reportData.print_evaluation_status"]}
              fileName="student_internship_status"
              field_headers={["First Name", "Last Name", "Business Name", "Student School", "Activity Log Status", "Approved Hours", "Total Hours", "Reflection Questions", "Reflection Questions Answered", "Final Project", "Project Summary", "MSDE Status", "Business Evaluations",]}
            />}
           </div>

           <div className='filter-on'>
            <MultiSelect label="Schools" name="school_attending" onChange={this.handleChange}
             value={this.state.school_attending}
             options={this.state.db.schools.map(s =>
               {
                 return {value: s.name, label: s.name};
               })}
           />
           <MultiSelect label="Programs" name="affiliated_program" onChange={this.handleChange}
            value={this.state.affiliated_program}
            options={this.state.db.programs.map(s =>
              {
                return {value: s.name, label: s.name};
              })}
          />
          <SingleSelect label="Graduation Year" name="selected_year" onChange={this.handleChange} value={this.state.selected_year}
            options={this.state.graduation_years.map(s => { return {value: s, label: s}; })}
          />
           </div>

           {this.state.list.length > 0 ?

            <div>
              <div className="row">
                <div className="col-12 table-responsive-sm">
                  <table className="table table-striped table-hover business-list-table">
                    <thead className="thead-light">
                     <tr>
                       <th scope="col"></th>
                       <th scope="col" width="50%">Student</th>
                       <th scope="col" width="30%">Application Count</th>
                       <th scope="col" width="20%">Last Application</th>
                     </tr>
                    </thead>
                    <tbody>
                      {this.state.list.map(student => (
                        <tr>
                          <td>
                            <Link to={'/profile/view/' + student._id}>
                            {student.profile_pic_id_fk ?
                              <Avatar className={"studnet_avatar sm"} src={student.profile_pic_data[0].path}  /> :
                              <Avatar className="student_avatar sm"><FontAwesomeIcon icon={["fal", "graduation-cap"]}/></Avatar> }
                            </Link>
                          </td>
                          <td><Link to={'/profile/view/' + student._id} title="View Student">{student.first_name} {student.last_name}</Link></td>
                          <td>{student.applications.length}</td>
                          <td>
                          {student.applications.length > 0 ? (
                            moment(student.applications[student.applications.length-1].createdAt).format('MM/DD/YYYY')
                          ) : (
                            "N/A"
                          )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            : <div className="search_above">No Work-Based Learning Opportunities Match These Filters</div> }


         </div>
       </div>
     )
   }
}
