import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {reorder, getListStyle} from "../Shared/DragDrop";
import ProgramForm from './ProgramForm';
import swal from 'sweetalert';
import _ from 'underscore';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

class ProgramList extends Component {

  state = {
      programs: [],
      reordered: false,
      viewAddNew: false
  }

  componentDidMount() {
   this.makeCall()
  }

  makeCall = () => {
    let link = "/api/getPrograms";
    if (this.props.user.role === "SuperAdmin")
      link = "/api/getDefaultPrograms";
    api({
      method: 'get',
      url: link,
    }).then(results => {
      this.setState({programs: results.data})
    });
  }

  toggleNew = (requery) => {
    this.setState({viewAddNew: !this.state.viewAddNew, selectedProgram: null});
    if (requery)
      this.makeCall();
  }
  
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value }, this.rerunSearch);
  };
  
  deleteConfirm = (user) => {
    let deleteProgram = this.deleteProgram;
    let message = "You are going to delete the program '" + user.name + "'";
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteProgram(user._id);
      } 
    });
  }
  
  deleteProgram = (id) => {
    let link = '/api/deleteProgram';
    let message = 'Program successfully deleted.';
    let makeCall = this.makeCall;
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then((response) => {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            makeCall();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }
  
  loadDefaultPrograms = () => {
    let makeCall = this.makeCall;
    axios({
      method: 'get',
      url: '/api/loadDefaultPrograms'
    }).then(function(response){
      makeCall();
      NotificationManager.success("You have saved the default Programs", 'Success');
    })
  }
  
  onDragEnd = (result) => {
    console.log("this is the result: ", result)
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const programs = reorder(
    this.state.programs,
    result.source.index,
    result.destination.index
  );
  console.log("this is the items:", programs)
  if (!_.isEqual(programs, this.state.programs)){
    console.log("reordered");
    this.setState({
      programs, reordered: true
    });
  }
  
}

saveReorder = () => {
  let json = this.state.programs;
  let link = '/api/reorderPrograms';
  let message = 'Reorder Successfull.';
  _.each(json, (j,k) => {
    j.ordinal = ++k
  });
  console.log("new json:", json);
  api({
      method: 'post',
      url: link,
      data: {
        json
      }
    }).then((response) => {
      console.log("this is the response:", response)
      this.setState({reordered: false})
        if (response.data === "success"){
          NotificationManager.success(message, 'Success');
        }
        else {
          NotificationManager.warning("Something went wrong", 'Uh Oh');
        }
      })
      
}

  printProgram = (obj) => {
    return (
      <tr key={obj._id}>
        <td>{obj.name}</td>
        <td>
        <span className="table_icons" onClick={()=>this.deleteConfirm(obj)}>
          <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
        </span>
        </td>
      </tr>
    )
  }
  
  edit = (obj) => {
    this.setState({selectedProgram: obj, viewAddNew: true})
  }


   render() {

     return (
       <div className="side-page">
         <div className="basic_form">

          <div className="page_header">
             <h1>{this.props.user.role === "SuperAdmin" ? "Default" : null } Programs
             {this.state.reordered ?
               <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder} >
                <FontAwesomeIcon icon={["fas", "save"]}  />Save Reorder
                </Button> :
               <span className="push-right withExplanation" onClick={this.toggleNew} >
                {!this.state.viewAddNew ? 
                  <FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
                  <FontAwesomeIcon icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> }
              </span>
             }
             </h1>
             {this.props.user.role === "SuperAdmin" ? 
             <div className="formExplanation">These Questions will be the defaults presented to new clients</div>
             :
             <div className="formExplanation">Programs are the areas of study for your interns. For example: (STEM-Science Technology Engineering & Math)</div>}
             </div>
             
             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                 {!this.state.viewAddNew ? 
                   this.state.programs.length > 0 ?
                     <table className="table table-striped table-hover business-list-table">
                       <thead className="thead-light">
                         <tr>
                            <th width="30px"></th>
                            <th scope="col" width="99%">Programs</th>
                            <th scope="col" width="30px">Delete</th>
                         </tr>
                       </thead>
                       <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="components">
                          {(provided, snapshot) => (
                            <tbody className="basic_form" {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}>
                              {this.state.programs.map((p, index) => (
                                <Draggable key={p._id} draggableId={p._id} index={index}>
                                {(provided, snapshot) => (
                                  <ProgramRow innerRef={provided.innerRef} obj={p} provided={provided}
                                  deleteConfirm={this.deleteConfirm}
                                  edit={this.edit}
                                  dragHandle={<span {...provided.dragHandleProps}><FontAwesomeIcon icon={["fas", "bars"]} className="draghandle"  /></span>} />
                                  )}
                                  </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  </tbody>
                                  )}  
                        </Droppable>
                        </DragDropContext>
                     </table>
                     : 
                     this.props.user.role === "SuperAdmin" ? <span>Please add default programs with the plus icon above</span> : <div className="two-buttons"><span className="first-button"><Button variant="contained" color="primary" onClick={this.toggleNew} >Add Your First Program</Button></span> <Button variant="contained" color="primary" onClick={this.loadDefaultPrograms} >Start with Default Programs</Button></div> 
                   : <ProgramForm toggleBack={this.toggleNew} selectedProgram={this.state.selectedProgram} /> }
                 </div>
               </div>
             </div>


         </div>
       </div>
     )
   }
}

export default connect(mapStateToProps)(ProgramList);

class ProgramRow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
     <tr {...provided.draggableProps}
     ref={innerRef} key={this.props.obj._id}>
       <td>{this.props.dragHandle}</td>
       <td><span className="makeitalink" onClick={()=>this.props.edit(this.props.obj)}>{this.props.obj.name}</span></td>
       <td><span className="table_icons" onClick={()=>this.props.deleteConfirm(this.props.obj)}>
       <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
     </span></td>
     </tr>
    )
  }
}
