import React, { Component } from 'react';
import axios from 'axios'
import _ from 'underscore';
import {ApplicantTable} from './_shared.jsx';

export default class OpportunityApplicants extends Component {

  state = {
    db: {},
  }

  componentDidMount() {
    this.makeCalls();
  }

  getApplicants = () => {
    return axios({
      method: 'get',
      url: '/api/getApplicants',
      params: {
        opp_id : this.props.match.params.id,
      }
    });
  }

  loadOpportunity = () => {
    return axios({
      method: 'get',
      url: '/api/loadOpportunity',
      params: {
        opp_id : this.props.match.params.id,
      }
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.loadOpportunity(), this.getApplicants() ])
    .then(axios.spread(function (opp, applicants) {
      let sorted_applicants = _.sortBy(applicants.data, function (o) { return o.student_id_fk.last_name})

      that.setState(state => ((state.db.opportunity = opp.data, state)));
      that.setState(state => ((state.db.applicants = sorted_applicants, state)));
    }));
  }

  render() {
    if (this.state.db.opportunity !== null) {
      //     const applicant = this.props.applicants[0];
          var applications = this.state.db.applicants;
          // console.log("applications:", applications)
      //
      //     console.log("applicant: ", applicant);
      //     if (applicant == null) {
      //       return null
      //     }
      //
          var app_active = _.filter(applications, function(app){
            if (app.status === "Accepted")
              return app;
          });

          var app_closed = _.filter(applications, function(app){
            if (app.status !== "New" && app.status !== 'Offered' && app.status !== 'Interview Scheduled' && app.status !== 'Accepted')
              return app;
          });

          var app_open = _.filter(applications, function(app){
            if (app.status === "New" || app.status === 'Offered' || app.status === 'Interview Scheduled')
              return app;
          });
    }

    if (this.state.db.opportunity === undefined || this.state.db.applicants === undefined)
      return null

    return (

      <div className="side-page">
        <div className="page_header"><h1>{this.state.db.opportunity.opportunity_title} Applicants</h1></div>

          {this.state.db.applicants.length === 0 ? (
          <div className="applicant_container">
            <h3>No Current Applicants</h3>
          </div>
          ) : (null)}

          {app_open.length ? (
          <div className="applicant_container">
            <h3>Open Applications</h3>
            <ApplicantTable data={app_open} />
          </div>
          ) : (null)}

          {app_active.length ? (
          <div className="applicant_container">
            <h3>Active Applications</h3>
            <ApplicantTable data={app_active} />
          </div>
          ) : (null)}

          {app_closed.length ? (
          <div className="applicant_container">
            <h3>Closed Applications</h3>
            <ApplicantTable data={app_closed} />
          </div>
          ) : (null)}

      </div>
    )
  }
}



/*

{app_open.length ? (
<div className="applicant_container">
  <h3>Open Applications</h3>
  <ApplicantTable data={app_open} />
</div>
) : (null)}

{app_active.length ? (
<div className="applicant_container">
  <h3>Active Applications</h3>
  <ApplicantTable data={app_active} />
</div>
) : (null)}

{app_closed.length ? (
<div className="applicant_container">
  <h3>Closed Applications</h3>
  <ApplicantTable data={app_closed} />
</div>
) : (null)}*/
