import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import {api} from "../../_helpers"
import swal from 'sweetalert';
import {NotificationManager} from 'react-notifications';

import InternPanelHours from './InternPanel_Hours.jsx';
import InternPanelBusiness from './InternPanel_Business.jsx'

export default class InternPanel extends Component {

  state = {
    business_status : this.props.data.business_status,
  }

  update_business_status = (new_state) => {
    let json = {
      project_id : this.props.data._id,
      business_status : new_state
    }


    swal({
      title: "Are you sure?",
      text: 'You are going to mark this work-based learning as ' + new_state + '. This cannot be undone.',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        api({
          method: 'post',
          url: '/api/business_internship_status_change',
          data: { json }
        }).then((response) => {
          NotificationManager.success('Work-Based learning was successfully ' + new_state + ' .', 'Work-Based Learning Update Successfull');
          this.setState({business_status : new_state});
        });
      }
    });
  }

  render() {

    return (
      <div className="internPanel">
      {this.props.data.student_id_fk.profile_pic_id_fk ?
        <div className={"avatar"}><img alt="profile pic" src={this.props.data.student_id_fk.profile_pic_id_fk.path} /></div> :
        <div className="avatar"><FontAwesomeIcon icon={["fal", "user-circle"]}/></div>
      }
        <div className="intern_name">{this.props.data.student_id_fk.first_name} {this.props.data.student_id_fk.last_name}</div>

        <InternPanelHours project_id={this.props.data._id} approved_hours={this.props.data.approved_hours} hours={this.props.data.hours} />
        <InternPanelBusiness project_id={this.props.data._id} evaluations={this.props.data.business_evaluations}  />

        <div className="manage_internship_block">
          <div className="description">Manage Internship:</div>

          {this.state.business_status === "open" ? (
            <div className="buttons">
              {this.props.data.business_evaluations.length === 2 ? (
                <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={() => this.update_business_status('completed')}>
                  Complete
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="default" disabled={true} className="btn_action">
                  Complete
                </Button>
              )}
              <Button type="submit" variant="contained" className="btn_action danger" onClick={() => this.update_business_status('terminated')}>
                Terminate
              </Button>
            </div>
          ) : (
            <div className="business_status">{this.state.business_status}</div>
          )}


        </div>

      </div>
    )
  }
}
