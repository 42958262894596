import React, { Component } from 'react';
import { connect } from 'react-redux';
import {api} from "../../_helpers"
import { Link } from 'react-router-dom';
import moment from 'moment';
import {Filters, SortBy} from './_shared.jsx';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    filters: state.filter
  };
};

class OpportunityBrowse extends Component {

  state = {
    sort: "distance",
    applications: []
  }

  componentDidMount() {
    this.getOpportunities();
    this.getMyApplications();
  }

  componentDidUpdate(prevProps) {
    //runs when the store updates the filter
    if (prevProps.filters !== this.props.filters) {
      this.getOpportunities();
    }
  }

  getMyApplications = () => {
    api({
      method: 'get',
      url: '/api/getMyApplications',
    }).then((response) => {
      console.log("get my applications:", response);
      if (response.data){
        // remove Rescinded applications
        this.setState({applications: response.data});
      }
      else {
        console.log("application error");
      }
    })
  }

  getOpportunities = () => {
    api({
      method: 'get',
      url: '/api/getOpportunities',
      params: {filter: this.props.filters}
    }).then((response) => {
      console.log("get new opportunities:", response);
      if (response.data){
        var filtered = response.data.filter((d, index, arr) =>{
            let dis = 0;
            if (d.opportunity_type !== "Virtual")
              dis = this.calculateDistance(d.location.lat, d.location.lon)

            d.distance = dis;

            return this.props.filters.radius === "" || this.props.filters.radius === "No" || dis <= this.props.filters.radius;
        });
        let sorted = _.sortBy(filtered, (o) => { return o[this.state.sort]; })
        this.setState(state => ((state.opportunities = sorted, state)));
      }
      else {
        console.log("get opportunity error");
      }

    });
  }

  handleSortChange = event => {
    this.setState({sort: event.target.value})
    let sorted = [];
    if (event.target.value.indexOf(".") >= 0) {
      let sort = event.target.value.split(".");
      sorted = _.sortBy(this.state.opportunities, function(o) { return o[sort[0]][sort[1]]; });
    }
    else {
      sorted = _.sortBy(this.state.opportunities, function(o) { return o[event.target.value]; });
    }

    this.setState(state => ((state.opportunities = sorted, state)));
  }

  calculateDistance = (olat, olon) =>{
  					var rad = function(x) {
  						return x * Math.PI / 180;
  					};
  					  var R = 6378137; // Earth’s mean radius in meter
  					  var dLat = rad(olat - this.props.user.location.lat);
  					  var dLong = rad(olon - this.props.user.location.lon);
  					  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  					    Math.cos(rad(this.props.user.location.lat)) * Math.cos(rad(olat)) *
  					    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  					  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  					  var d = (R * c)*.000621371192; //convert to miles
  					  return (d.toFixed(2));
  		}

  render() {
    const opportunities = this.state.opportunities;
    const user = this.props.user;


    if (opportunities == null || user == null) {
      return null
    }
    else {
        opportunities.map(opp => (
        _.find(this.state.applications, function(app) {
            if(app.opportunity_id_fk._id === opp._id && app.status !== "Rescinded")
              opp.application = app;
        })
      ));
    }

    //let app = "";

    return (
      <div className="side-page">
        <div className="basic_form">

          <div className="page_header"><h1>Browse Opportunities</h1></div>

          <div className="row">
            <div className="col-md-4"><Filters focusAreas={this.props.user.focus_areas} /></div>

              {opportunities.length > 0 ? (
                <div className="col-md-8 table-responsive-sm">
                <SortBy onChange={this.handleSortChange} sort={this.state.sort} />
              <table className="table table-striped table-hover student-opportunity-list">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Opportunity</th>
                    <th scope="col">Apply Before</th>
                    <th scope="col">Availability</th>
                    <th scope="col">Distance</th>
                  </tr>
                </thead>
                <tbody>
                  {opportunities.map(opp => (
                    <tr key={opp._id}>
                      <td>
                      <div className="opportunity-title">
                        {opp.application ? (
                          opp.application.status === "Accepted" ?
                            (<FontAwesomeIcon icon={["fas", "check-circle"]} title="You Have Been Accepted for This Opportunity"/> ) :
                            (<FontAwesomeIcon icon={["fal", "check-circle"]} title="You Applied for this Opportunity"/> )
                        ) : null }
                        {' '}<Link to={'/opportunity/view/' + opp._id} title="View Opportunity">{opp.opportunity_title}</Link>
                      </div>
                      <div className="business-name"><Link to={'/profile/view/' + opp.business_id_fk._id} title="Business View">{opp.business_id_fk.business_name}</Link></div>
                      </td>
                      <td>{moment(opp.application_close).format("MM/DD/YYYY")}</td>
                      <td>
                        {parseInt(opp.available_slots) - opp.reportData.accepted_count == 0 ? (
                          "Opportunity Full"
                        ) : (
                          parseInt(opp.available_slots) - opp.reportData.accepted_count
                        )}
                      </td>
                      <td>{opp.distance === "NaN" ? (<>UNKNOWN</>) : opp.distance === 0 ? (<>Virtual</>) :( <>{opp.distance} mi</>)}</td>
                    </tr>
                ))}
                </tbody>
              </table>
              </div>
            ) : "No Opportunites match your filters"}

          </div>

        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(OpportunityBrowse);
