import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';

import {setUser} from '../../ducks';

const actions = {
  setUser,
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    is_masquerade : state.auth.isMasquerade,
    admin_id : state.auth.masquerade_user,
    isAuthenticated: state.auth.isAuthenticated,
    settings: state.settings
  };
};

class ConnectedFooter extends Component {

  state = {
    show_hamburger_menu : false,
  }

  open_hamburger = () => {
    this.setState({show_hamburger_menu : true})
  }

  handleClose = () => {
    this.setState({show_hamburger_menu : false})
  }

  logout = () => {
    axios({
      method: 'get',
      url: '/auth/logout',
    }).then(function (response) {
      console.log(response);
      document.location = '/';
    });
  }

  render() {
    return (
      <>

        {this.props.isAuthenticated === false ? (
          <nav className="navbar fixed-bottom navbar-expand-sm student_icon_footer d-sm-none ">
            <Link className="nav-link" to='/login'><div>
              <FontAwesomeIcon icon={["far", "sign-in-alt"]}/>
              <br />Login
            </div></Link>
          </nav>
        ) : (
          <nav className="navbar fixed-bottom navbar-expand-sm student_icon_footer d-sm-none ">
            <Link className="nav-link" to='/opportunity/browse'><div>
              <FontAwesomeIcon icon={["fal", "search"]}/>
              <br />Search
            </div></Link>

            <Link className="nav-link" to='/applications/list'><div>
              <FontAwesomeIcon icon={["fal", "desktop-alt"]} />
              <br />Applications
            </div></Link>

            <Link className="nav-link" to='/projects/viewall'><div>
              <FontAwesomeIcon icon={["fal", "briefcase"]} />
              <br />Jobs
            </div></Link>

            <Link className="nav-link" to='/profile'><div>
              <FontAwesomeIcon icon={["fal", "user-circle"]} />
              <br />Profile
            </div></Link>

            <Link className="nav-link" to='#' onClick={this.open_hamburger}><div>
              <FontAwesomeIcon icon={["fal", "bars"]} />
              <br />Menu
            </div></Link>
          </nav>
        )}


        <section className="footer d-none d-sm-none d-md-block">
          <div className="row">
            <div className="col-12 icon-list">
              {this.props.settings.facebook ? <a target="_blank" rel="noopener noreferrer" href={this.props.settings.facebook}><FontAwesomeIcon icon={["fab", "facebook-f"]} mask={['fas', 'circle']} transform="shrink-4.5"/></a> : null}
              {this.props.settings.twitter ? <a target="_blank" rel="noopener noreferrer" href={this.props.settings.twitter}><FontAwesomeIcon icon={["fab", "twitter"]} mask={['fas', 'circle']} transform="shrink-4.5"/></a> : null}
              {this.props.settings.instagram ? <a target="_blank" rel="noopener noreferrer" href={this.props.settings.instagram}><FontAwesomeIcon icon={["fab", "instagram"]} mask={['fas', 'circle']} transform="shrink-4.5"/></a> : null}
              {this.props.settings.snapchat ? <a target="_blank" rel="noopener noreferrer" href={this.props.settings.snapchat}><FontAwesomeIcon icon={["fab", "snapchat-ghost"]} mask={['fas', 'circle']} transform="shrink-4.5"/></a> : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 copy">
                Powered by admintern
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 copy">
                &copy; {this.props.settings.full_name} {new Date().getFullYear()}
            </div>
          </div>
        </section>

        <Modal show={this.state.show_hamburger_menu} onHide={this.handleClose} className="hambuger_side_menu" dialogClassName="modal-dialog-slideout" animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Menu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><Link to="/student_resources" onClick={this.handleClose}> <FontAwesomeIcon icon={["fal", "head-side-brain"]}/>Resources</Link></div>
            <div><Link to="/password" onClick={this.handleClose}> <FontAwesomeIcon icon={["fal", "key"]}/> Change Password</Link></div>
            <div><Link to="#" onClick={this.logout}> <FontAwesomeIcon icon={["far", "sign-out-alt"]}/>Logout</Link></div>


          </Modal.Body>
        </Modal>

        </>

    )
  }
}

const Footer = connect(mapStateToProps, actions)(ConnectedFooter);
export default withRouter(Footer);
