import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {faPlus, faTimes, faSpinner, faUser, faSearch, faFileUser, faFileAlt,faKey, faFileSpreadsheet, faGraduationCap, faCheckCircle as faLightCheckCircle, faUpload as faLightUpload, faBuilding, faEye, faEnvelope, faMapMarkerAlt as faLightMarker, faPhoneSquare, faCalendarAlt, faClock, faUsdCircle, faProjectDiagram, faTrashAlt as faTrashLight, faQuestionCircle, faLockAlt, faLockOpenAlt, faUserSecret, faEdit as faLightEdit, faTimesCircle, faPlusCircle, faFileSignature, faKeySkeleton, faGripHorizontal, faDoorClosed, faDoorOpen, faDesktopAlt, faBriefcase, faBars, faUserCircle as faLightUserCircle, faHeadSideBrain, faPhonePlus, faExclamationCircle, faHeartRate, faSchool, faSquare} from '@fortawesome/pro-light-svg-icons'
import {faUserPlus, faBusSchool, faMapMarkerAlt, faAddressCard, faFemale, faSignInAlt, faSignOutAlt, faUpload, faDownload, faCog, faTrashAlt, faEdit, faCopy, faUsers, faToggleOn, faToggleOff, faChevronCircleRight, faCheckCircle as faCheckCircleOutline, faCircle as faCircleOutline, faCircleNotch} from '@fortawesome/pro-regular-svg-icons'
import {faFacebookF, faTwitter, faInstagram, faSnapchatGhost} from '@fortawesome/free-brands-svg-icons'
import {faSave, faBars as faBarsSolid, faCircle, faUserCircle, faLock, faCaretCircleLeft, faUserTie, faCheckCircle, faUserSecret as faUserSecretSolid, faKey as faKeySolid, faAngleDown, faAngleUp, faComments, faPhone, faEnvelope as faEnvelopeSolid, faBuilding as faBuildingSolid, faPeopleArrows, faQuestion, faInfoCircle, faCheckSquare} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faBarsSolid,
  faSave,
  faAddressCard,
  faTimes,
  faBusSchool,
  faSpinner,
  faFileSpreadsheet,
  faMapMarkerAlt,
  faLightMarker,
  faPlus,
  faKey,
  faTrashLight,
  faUser,
  faSearch,
  faFemale,
  faEnvelope,
  faPhoneSquare,
  faCalendarAlt,
  faClock,
  faUsdCircle,
  faFileUser,
  faFileAlt,
  faLightEdit,
  faLightUpload,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCog,
  faFacebookF,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faCircle,
  faUserCircle,
  faTrashAlt,
  faLock,
  faGraduationCap,
  faBuilding,
  faCaretCircleLeft,
  faEdit,
  faCopy,
  faEye,
  faUserTie,
  faUsers,
  faProjectDiagram,
  faLockAlt,
  faQuestionCircle,
  faLockOpenAlt,
  faCheckCircle,
  faLightCheckCircle,
  faUpload,
  faDownload,
  faUserSecret,
  faUserSecretSolid,
  faTimesCircle,
  faPlusCircle,
  faFileSignature,
  faKeySolid,
  faToggleOn,
  faToggleOff,
  faKeySkeleton,
  faChevronCircleRight,
  faCheckCircleOutline,
  faCircleOutline,
  faGripHorizontal,
  faDoorClosed,
  faDesktopAlt,
  faBriefcase,
  faBars,
  faLightUserCircle,
  faAngleDown,
  faAngleUp,
  faHeadSideBrain,
  faComments,
  faPhonePlus,
  faPhone,
  faEnvelopeSolid,
  faBuildingSolid,
  faPeopleArrows,
  faQuestion,
  faInfoCircle,
  faExclamationCircle,
  faDoorOpen,
  faHeartRate,
  faSchool,
  faSquare,
  faCheckSquare,
  faCircleNotch
)

dom.watch()
